import {
  useSetAuthTokenToHttpClient,
  useSetUserToHttpClientLogger,
  useClearSessionOnRefreshTokenError,
  useForgetLoggedInTabOnSignOut,
} from 'lib/auth';
import { useLogRouterEvents } from 'lib/router';
import { useMergeCartOnLogin, useForgetCartOnLogout } from 'lib/cart';
import useClearActiveAddressOnLogout from 'lib/address/useClearAddressOnLogout';

/**
 * run hooks that are dependent on auth session
 * should be rendered below AuthProvider
 */
const AuthHooks = () => {
  useSetAuthTokenToHttpClient();

  useSetUserToHttpClientLogger();

  useClearSessionOnRefreshTokenError();

  useLogRouterEvents();

  useForgetLoggedInTabOnSignOut();

  useMergeCartOnLogin();

  useForgetCartOnLogout();

  useClearActiveAddressOnLogout();

  return null;
};

export default AuthHooks;
