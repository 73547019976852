import httpClient, { getResponseData } from 'lib/httpClient';

export const getUserPaymentMethods = async () => {
  try {
    const response = await httpClient.get(
      '/payments/stripe/paymentmethod',
    );
    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getPaymentConfiguration = async () => {
  try {
    const response = await httpClient.get(
      '/payments/stripe/configurations',
    );
    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getUserCards = async () => {
  try {
    const response = await httpClient.get(
      `/payments/stripe/paymentmethod`,
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getSetupCardClientSecret = async () => {
  try {
    const response = await httpClient.post(
      '/payments/stripe/setupintent',
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getPaymentIntent = async (orderId, paymentMethod) => {
  try {
    const payload = {
      order_id: orderId,
      ...(paymentMethod && { payment_method: paymentMethod }),
    };
    const response = await httpClient.post(
      `/payments/stripe/paymentintent`,
      payload,
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getPaymentIntentByOrderId = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/orders/${orderId}/paymentintent`,
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const getCardPaymentClientSecret = async (orderId) => {
  try {
    const response = await httpClient.post(
      `/payments/stripe/paymentintent`,
      { order_id: orderId },
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};

export const deleteACard = async (cardId) => {
  try {
    const response = await httpClient.delete(
      `/payments/stripe/paymentmethod/${cardId}`,
    );

    return getResponseData(response);
  } catch (err) {
    throw err;
  }
};
