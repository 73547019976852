import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import datetime from '../datetime';

const CountDownTimer = ({ value, onComplete }) => {
  const _countDownRef = useRef();
  if (!value) return '-';

  const parsedValue = datetime(value);

  const _renderPart = (value, preText, splitter = ',') => {
    return (
      <span>
        {value} {preText}
        {value > 1 ? 's' : ''}
        {splitter}{' '}
      </span>
    );
  };

  const _renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <>
        {days > 0 && _renderPart(days, 'day')}
        {hours > 0 && _renderPart(hours, 'hour')}
        {minutes > 0 && _renderPart(minutes, 'minute')}
        {_renderPart(seconds, 'second', '')}
      </>
    );
  };

  return (
    <Countdown
      key={parsedValue.toISOString()}
      ref={_countDownRef}
      date={parsedValue}
      renderer={_renderer}
      onComplete={() => {
        onComplete(() => {
          _countDownRef.current.start();
        });
      }}
    />
  );
};

CountDownTimer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  onComplete: PropTypes.func,
};

export default CountDownTimer;
