import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';

const CardPaymentGatewayProvider = ({ loader, children }) => {
  return <Elements stripe={loader}>{children}</Elements>;
};

CardPaymentGatewayProvider.propTypes = {
  loader: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  children: PropTypes.node.isRequired,
};

export default CardPaymentGatewayProvider;
