import config from 'appConfig';

export const makePrice = (
  amount,
  locale = config.locale,
  currency = config.currency,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount);
};
