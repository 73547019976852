class AddToCartError extends Error {
  constructor({ product, serverError }) {
    const { code, message, meta } = serverError?.errors || {};

    super(
      message || `Something went wrong when adding ${product.name}`,
    );

    this.name = 'AddToCartError';
    this.code = code;
    this.meta = meta;
    this.product = product;
  }
}

export default AddToCartError;
