import { CSSProperties, HTMLProps } from 'react';
import { CustomIcon } from './CustomIcon';

export enum IconIconSet {
  MOLLA = 'molla',
  CUSTOM = 'custom',
  BOOTSTRAP = 'bootstrap',
}
export type IconIconSetString = `${IconIconSet}`;

type ParentProps = HTMLProps<HTMLDivElement>;

type Props = {
  [Property in keyof ParentProps]?: ParentProps[Property];
} & {
  name: string;
  iconSet?: IconIconSet | IconIconSetString;
  fontSize?: CSSProperties['fontSize'];
  style?: CSSProperties;
};

export const Icon = ({
  name,
  iconSet = IconIconSet.MOLLA,
  fontSize = 24,
  style = {},
  ...props
}: Props) => {
  switch (iconSet) {
    case IconIconSet.MOLLA:
      return (
        <i
          className={`icon-${name}`}
          style={{ ...style, fontSize }}
          {...props}
        ></i>
      );

    case IconIconSet.CUSTOM:
      return (
        <CustomIcon
          name={name}
          fontSize={fontSize}
          style={style}
          {...props}
        />
      );

    case IconIconSet.BOOTSTRAP:
      return (
        <i
          className={`bi bi-${name}`}
          style={{ ...style, fontSize }}
          {...props}
        />
      );

    default:
      return null;
  }
};
