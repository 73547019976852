import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DELIVERY_METHODS, STEPS } from '../constants';
import { CollectionPoint } from 'lib/collection-points';
import { Address } from 'lib/address/types';
import { isEmpty, isNil, isNull } from 'lib/javascript';
import { DeliveryMethod, Step } from '../types';
import { DONATION_TYPE, DonationType } from 'lib/donation';

interface CheckoutContextProviderProps {
  currentStep: Step;
  setCurrentStep: Dispatch<SetStateAction<Step>>;
  deliveryMethod: DeliveryMethod;
  setDeliveryMethod: Dispatch<SetStateAction<DeliveryMethod>>;
  deliveryDateTime: string | null;
  setDeliveryDateTime: Dispatch<SetStateAction<string | null>>;
  collectionPoint: CollectionPoint | null;
  setCollectionPoint: Dispatch<
    SetStateAction<CollectionPoint | null>
  >;
  collectionPointSlot: Date | null;
  setCollectionPointSlot: Dispatch<
    SetStateAction<Date | string | null>
  >;
  isFetchingCollectionPointSlot: boolean;
  setIsFetchingCollectionPointSlot: Dispatch<SetStateAction<boolean>>;
  collectionPointAddress?: Address;
  isCollectionPointLoaded: boolean;
  needUtensils: boolean;
  setNeedUtensils: Dispatch<SetStateAction<boolean>>;
  isLeaveCoolerBag: boolean;
  setIsLeaveCoolerBag: Dispatch<SetStateAction<boolean>>;
  deliveryNotes: string;
  setDeliveryNotes: Dispatch<SetStateAction<string>>;
  hasSavedDeliveryNotes: boolean;
  saveDeliveryNotes: boolean;
  setSaveDeliveryNotes: Dispatch<SetStateAction<boolean>>;
  isAgreeToTnc: boolean;
  setIsAgreeToTnc: Dispatch<SetStateAction<boolean>>;
  donationType: DonationType;
  setDonationType: Dispatch<SetStateAction<DonationType>>;
  donationAmount: number;
  setDonationAmount: Dispatch<SetStateAction<number>>;
}

export const defaultValues: CheckoutContextProviderProps = {
  currentStep: STEPS.AUTH,
  setCurrentStep: () => {},
  deliveryMethod: DELIVERY_METHODS.DOORSTEP,
  setDeliveryMethod: () => {},
  deliveryDateTime: null,
  setDeliveryDateTime: () => {},
  collectionPoint: null,
  setCollectionPoint: () => {},
  collectionPointSlot: null,
  setCollectionPointSlot: () => {},
  isFetchingCollectionPointSlot: false,
  setIsFetchingCollectionPointSlot: () => {},
  collectionPointAddress: undefined,
  needUtensils: false,
  setNeedUtensils: () => {},
  isLeaveCoolerBag: false,
  setIsLeaveCoolerBag: () => {},
  deliveryNotes: '',
  setDeliveryNotes: () => {},
  hasSavedDeliveryNotes: false,
  saveDeliveryNotes: false,
  setSaveDeliveryNotes: () => {},
  isCollectionPointLoaded: false,
  isAgreeToTnc: false,
  setIsAgreeToTnc: () => {},
  donationType: DONATION_TYPE.WITHOUT,
  setDonationType: () => {},
  donationAmount: 0,
  setDonationAmount: () => {},
};

export const CheckoutContext = createContext<CheckoutContextProviderProps>(
  defaultValues,
);

export const CheckoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentStep, setCurrentStep] = useState<Step>(
    defaultValues.currentStep,
  );
  const [
    deliveryMethod,
    setDeliveryMethod,
  ] = useState<DeliveryMethod>(defaultValues.deliveryMethod);
  const [deliveryDateTime, setDeliveryDateTime] = useState<
    string | null
  >(null);
  const [
    collectionPoint,
    setCollectionPoint,
  ] = useState<CollectionPoint | null>(null);

  const [
    collectionPointAddress,
    setCollectionPointAddress,
  ] = useState<Address | undefined>(undefined);

  const _setCollectionPoint = (
    collectionPoint: CollectionPoint | null,
  ) => {
    setCollectionPoint(collectionPoint);
    setCollectionPointAddress(collectionPoint?.address);
  };

  const [
    collectionPointSlot,
    setCollectionPointSlot,
  ] = useState<Date | null>(null);

  const [
    isFetchingCollectionPointSlot,
    setIsFetchingCollectionPointSlot,
  ] = useState(false);

  const [needUtensils, setNeedUtensils] = useState(false);
  const [isLeaveCoolerBag, setIsLeaveCoolerBag] = useState(false);
  const [deliveryNotes, setDeliveryNotes] = useState('');
  const [saveDeliveryNotes, setSaveDeliveryNotes] = useState(false);
  const [isAgreeToTnc, setIsAgreeToTnc] = useState(false);
  const [donationType, setDonationType] = useState(
    defaultValues.donationType,
  );
  const [donationAmount, setDonationAmount] = useState(
    defaultValues.donationAmount,
  );

  const isCollectionPointLoaded =
    !isFetchingCollectionPointSlot &&
    !isNull(collectionPoint) &&
    !isNull(collectionPointSlot) &&
    !isNull(collectionPointAddress);

  const contextValue = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      deliveryMethod,
      setDeliveryMethod,
      deliveryDateTime,
      setDeliveryDateTime,
      collectionPoint,
      setCollectionPoint: _setCollectionPoint,
      collectionPointAddress,
      collectionPointSlot,
      setCollectionPointSlot,
      setIsFetchingCollectionPointSlot,
      isFetchingCollectionPointSlot,
      needUtensils,
      setNeedUtensils,
      isLeaveCoolerBag,
      setIsLeaveCoolerBag,
      deliveryNotes,
      setDeliveryNotes,
      hasSavedDeliveryNotes:
        !isEmpty(deliveryNotes) && !isNil(deliveryNotes),
      saveDeliveryNotes,
      setSaveDeliveryNotes,
      isCollectionPointLoaded,
      isAgreeToTnc,
      setIsAgreeToTnc,
      donationType,
      setDonationType,
      donationAmount,
      setDonationAmount,
    }),
    [
      currentStep,
      deliveryMethod,
      deliveryDateTime,
      collectionPoint,
      collectionPointAddress,
      collectionPointSlot,
      isFetchingCollectionPointSlot,
      needUtensils,
      isLeaveCoolerBag,
      deliveryNotes,
      saveDeliveryNotes,
      isCollectionPointLoaded,
      isAgreeToTnc,
      donationType,
      donationAmount,
    ],
  );

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => useContext(CheckoutContext);
