import React, { CSSProperties } from 'react';
import config from 'appConfig';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import dynamic from 'next/dynamic';
import { toInteger } from 'lib/javascript';
import { LazyLoadImageProps } from 'react-lazy-load-image-component';

const LazyLoadImage = dynamic<LazyLoadImageProps>(() =>
  import('react-lazy-load-image-component').then(
    (mod) => mod.LazyLoadImage,
  ),
);

enum IMAGE_COMPONENT {
  NEXT = 'next/image',
  NEXT_IMGIX = 'next/image:imgix',
  REACT_LAZY_LOAD = 'react-lazy-load-image-component',
}

export enum IMAGE_LAYOUT {
  FIXED = 'fixed',
  INTRINSIC = 'intrinsic',
  RESPONSIVE = 'responsive',
  FILL = 'fill',
}
export type ImageLayoutString = `${IMAGE_LAYOUT}`;

export enum IMAGE_LOADING_METHOD {
  LAZY = 'lazy',
  EAGER = 'eager',
}
export type ImageLoadingMethodString = `${IMAGE_LOADING_METHOD}`;

type ImgElementStyle = NonNullable<
  JSX.IntrinsicElements['img']['style']
>;

type ParentProps = LazyLoadImageProps & NextImageProps;
export type ImageProps = {
  [Property in keyof ParentProps]?: ParentProps[Property];
} & {
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  layout?: IMAGE_LAYOUT | ImageLayoutString;
  sizes?: string;
  priority?: boolean;
  unoptimized?: boolean;
  objectFit?: ImgElementStyle['objectFit'];
  objectPosition?: ImgElementStyle['objectPosition'];
  loading?: IMAGE_LOADING_METHOD | ImageLoadingMethodString;
  quality?: number;
};

/* -------------------------------------------------------------------------- */
/*                                    utils                                   */
/* -------------------------------------------------------------------------- */

/**
 * Isomorphic base64 that works on the server and client
 */
export function toBase64(str) {
  if (typeof window === 'undefined') {
    return Buffer.from(str).toString('base64');
  } else {
    return window.btoa(str);
  }
}

const getWrapperStyle = ({ layout, widthInt, heightInt }) => {
  let wrapperStyle;

  switch (layout) {
    case IMAGE_LAYOUT.RESPONSIVE:
      wrapperStyle = {
        display: 'block',
        overflow: 'hidden',
        position: 'relative',

        boxSizing: 'border-box',
        margin: 0,
      };

      break;

    case IMAGE_LAYOUT.INTRINSIC:
      wrapperStyle = {
        display: 'inline-block',
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'relative',
        boxSizing: 'border-box',
        margin: 0,
      };

      break;

    case IMAGE_LAYOUT.FIXED:
      wrapperStyle = {
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'inline-block',
        position: 'relative',
        width: widthInt,
        height: heightInt,
      };

      break;

    case IMAGE_LAYOUT.FILL:
      wrapperStyle = {
        display: 'block',
        overflow: 'hidden',

        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,

        boxSizing: 'border-box',
        margin: 0,
      };

      break;

    default:
  }

  return wrapperStyle;
};

const getSizerStyle = ({
  layout,
  widthInt,
  heightInt,
}): CSSProperties => {
  switch (layout) {
    case IMAGE_LAYOUT.RESPONSIVE:
      const quotient = heightInt / widthInt;
      const paddingTop = isNaN(quotient)
        ? '100%'
        : `${quotient * 100}%`;

      return {
        display: 'block',
        boxSizing: 'border-box',
        paddingTop,
      };

    case IMAGE_LAYOUT.INTRINSIC:
      return {
        boxSizing: 'border-box',
        display: 'block',
        maxWidth: '100%',
      };

    default:
      return {};
  }
};

const getSizerSvg = ({ layout, widthInt, heightInt }) => {
  switch (layout) {
    case IMAGE_LAYOUT.INTRINSIC:
      return `<svg width="${widthInt}" height="${heightInt}" xmlns="http://www.w3.org/2000/svg" version="1.1"/>`;

    default:
      return;
  }
};

const isSrcRelativePath = (src) => src[0] === '/';

const normalizeSrc = (src) => {
  if (isSrcRelativePath(src)) return src.slice(1);
  return new URL(src).pathname.slice(1);
};

function imgixLoader({ src, width, quality }) {
  // Demo: https://static.imgix.net/daisy.png?auto=format&fit=max&w=300

  const root = isSrcRelativePath(src)
    ? config.imgixWebFolderSourceDomain // image is imported from public folder if it's a relative path
    : config.imgixS3SourceDomain; // otherwise, the image is uploaded to S3

  const url = new URL(`${root}/${normalizeSrc(src)}`);
  const params = url.searchParams;

  params.set('auto', params.get('auto') || 'format');
  params.set('fit', params.get('fit') || 'max');
  params.set('w', params.get('w') || width.toString());

  if (quality) {
    params.set('q', quality.toString());
  }

  return url.href;
}

const getLoader = (configImageComponent) => {
  if (configImageComponent === IMAGE_COMPONENT.NEXT_IMGIX)
    return imgixLoader;

  return undefined; // let Next.js determine default loader from next.config.js
};

const getImageExtension = (src) => src.split('.').pop();

const isGif = (src) => getImageExtension(src) === 'gif';

const getPreferredImageLoadingMethod = () =>
  config.featureToggle.imageComponent;

const isPreferReactLazyLoad = () =>
  getPreferredImageLoadingMethod() ===
  IMAGE_COMPONENT.REACT_LAZY_LOAD;

const isPreferNextJsDefaultLoader = () =>
  getPreferredImageLoadingMethod() === IMAGE_COMPONENT.NEXT;

const isPreferNextJsImgIXLoader = () =>
  getPreferredImageLoadingMethod() === IMAGE_COMPONENT.NEXT_IMGIX;

/* -------------------------------------------------------------------------- */
/*                                  component                                 */
/* -------------------------------------------------------------------------- */

/**
 * A wrapper on top of next/image, or react-lazy-load-image-component
 * documentation: https://nextjs.org/docs/api-reference/next/image
 * documentation: https://www.npmjs.com/package/react-lazy-load-image-component
 */
const Image = ({
  src,
  width,
  height,
  alt,
  layout,
  sizes,
  priority,
  unoptimized,
  objectFit,
  objectPosition,
  loading,
  ...others
}: ImageProps) => {
  const shouldUseReactLazyLoad =
    isPreferReactLazyLoad() ||
    (isGif(src) && isPreferNextJsImgIXLoader());
  // imgIX only support animated GIF on Enterprise plan, so use react-lazy-load-image-component for rendering GIFs
  // https://imgix.com/pricing

  if (shouldUseReactLazyLoad) {
    const widthInt = toInteger(width);
    const heightInt = toInteger(height);

    const hasWidthAndHeight =
      typeof widthInt !== 'undefined' &&
      typeof heightInt !== 'undefined';

    const invalidPropCombination =
      !hasWidthAndHeight && layout !== IMAGE_LAYOUT.FILL;

    if (
      invalidPropCombination &&
      process.env.NODE_ENV !== 'production'
    ) {
      throw new Error(
        `Image with src "${src}" must use "width" and "height" properties or "layout='fill'" property.`,
      );
    }

    const wrapperStyle = getWrapperStyle({
      layout,
      widthInt,
      heightInt,
    });

    const sizerStyle = getSizerStyle({
      layout,
      widthInt,
      heightInt,
    });

    const sizerSvg = getSizerSvg({
      layout,
      widthInt,
      heightInt,
    });

    const imgStyle: CSSProperties = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,

      boxSizing: 'border-box',
      padding: 0,
      border: 'none',
      margin: 'auto',

      display: 'block',
      width: 0,
      height: 0,
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '100%',
      maxHeight: '100%',

      objectFit,
      objectPosition,
    };

    return (
      <div style={wrapperStyle}>
        {sizerStyle ? (
          <div style={sizerStyle}>
            {sizerSvg ? (
              <img
                style={{
                  maxWidth: '100%',
                  display: 'block',
                  margin: 0,
                  border: 'none',
                  padding: 0,
                }}
                alt=""
                aria-hidden={true}
                role="presentation"
                src={`data:image/svg+xml;base64,${toBase64(
                  sizerSvg,
                )}`}
              />
            ) : null}
          </div>
        ) : null}
        <LazyLoadImage
          {...others}
          alt={alt}
          height={height}
          src={src}
          width={width}
          style={imgStyle}
        />
      </div>
    );
  }

  if (isPreferNextJsDefaultLoader() || isPreferNextJsImgIXLoader()) {
    const loader = getLoader(config.featureToggle.imageComponent);

    return (
      <NextImage
        {...others}
        src={src}
        width={width}
        height={height}
        alt={alt}
        layout={layout}
        sizes={sizes}
        priority={priority}
        unoptimized={unoptimized}
        objectFit={objectFit}
        objectPosition={objectPosition}
        loading={loading}
        loader={loader}
      />
    );
  }

  return null;
};

Image.defaultProps = {
  alt: undefined,
  layout: 'intrinsic',
  sizes: undefined,
  quality: 75,
  priority: false,
  objectFit: undefined,
  objectPosition: undefined,
  unoptimized: false,
};

export { Image };
