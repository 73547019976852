import Script, { ScriptProps } from 'next/script';
import usePageView from './usePageView';

interface GoogleTagManagerProps {
  gtmId?: string;
  strategy?: ScriptProps['strategy'];
  nonce?: ScriptProps['nonce'];
  withNoScript?: boolean;
}

type WithPageViews = GoogleTagManagerProps & {
  trackPageViews?: boolean;
};

const GoogleTagManager = ({
  gtmId,
  strategy = 'afterInteractive',
  nonce,
  withNoScript,
  trackPageViews,
}: WithPageViews) => {
  const _gtmId = process.env.NEXT_PUBLIC_GTM_ID ?? gtmId;

  usePageView({ disabled: !trackPageViews });

  if (!_gtmId) return null;

  return (
    <>
      {withNoScript && (
        <noscript>
          <iframe
            title="gtm-noscript"
            src={`https://www.googletagmanager.com/ns.html?id=${_gtmId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}

      <Script
        id="google-tag-manager"
        strategy={strategy}
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;
            ${
              nonce
                ? `var n=d.querySelector('[nonce]');n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));`
                : ''
            }f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${_gtmId}')
          `,
        }}
      />
    </>
  );
};

export default GoogleTagManager;
