import {
  crypto_box_seal,
  from_base64,
  to_base64,
  base64_variants,
} from 'libsodium-wrappers';
import { getEncryptionPublicKeyBase64 } from './api';

export const encryptWithPublicKey = (message, publicKey) =>
  to_base64(
    crypto_box_seal(message, publicKey),
    base64_variants.ORIGINAL,
  );

export const encrypt = async (message) => {
  try {
    const publicKeyBase64 = await getEncryptionPublicKeyBase64();

    const publicKey = from_base64(
      publicKeyBase64,
      base64_variants.ORIGINAL,
    );

    return encryptWithPublicKey(message, publicKey);
  } catch (err) {
    console.error(err);
  }
};
