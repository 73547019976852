import { useAuth } from 'lib/auth';
import { deleteACard, getUserPaymentMethods } from '../api';
import { useCallback, useState } from 'react';
import { useGet } from 'lib/swr-crud';
import { APIErrorResponse, APISingleResponse } from 'lib/utils';
import {
  PaymentMethodsResponse,
  CreditCardDetails,
  PaymentMethodDetails,
} from '../types';
import { AxiosError } from 'axios';
import { ConfirmCardSetupData } from '@stripe/stripe-js';
import { uniq, get, isEmpty } from 'lib/javascript';
import usePayment from '../usePayment';

const useUserCards = () => {
  const { session, loading: isAuthLoading } = useAuth();
  const [removingCardIds, setRemovingCardIds] = useState<string[]>(
    [],
  );

  const {
    isLoading: isCardPaymentGatewayLoading,
    getCardInput,
    confirmCardSetup,
  } = usePayment();

  const shouldFetch = !isAuthLoading && session;

  const {
    data,
    error,
    mutate,
    isLoading: isFetchingUserCards,
  } = useGet<
    APISingleResponse<PaymentMethodsResponse>,
    AxiosError<APIErrorResponse>
  >(
    shouldFetch ? '/payments/stripe/cards' : null,
    async () => await getUserPaymentMethods(),
  );

  const isLoading =
    isAuthLoading ||
    isFetchingUserCards ||
    isCardPaymentGatewayLoading;

  const paymentMethodList = data?.data ?? null;
  const cards: CreditCardDetails[] = (
    paymentMethodList?.data ?? []
  ).map((paymentMethod) => ({
    ...paymentMethod.card,
    id: paymentMethod.id,
  }));

  const isCardBeingRemoved = useCallback(
    (cardId: string) => removingCardIds.includes(cardId),
    [removingCardIds],
  );

  const getDuplicateCards = (cards: PaymentMethodDetails[]) => {
    const uniqueCardIdentifierField = 'card.fingerprint';
    const uniqueCardIdentifiers: string[] = [];

    // if there are more than 1 same cards, keep the 1st card and return the rest as duplicate
    const duplicateCards = cards.filter((card) => {
      const uniqueCardIdentifier: string = get(
        card,
        uniqueCardIdentifierField,
      );

      const isDuplicate = uniqueCardIdentifiers.includes(
        uniqueCardIdentifier,
      );

      uniqueCardIdentifiers.push(uniqueCardIdentifier);

      return isDuplicate;
    });

    return duplicateCards;
  };

  const setCardIsRemoving = (cardId: string, isRemoving: boolean) => {
    setRemovingCardIds((removingCardIds) => {
      if (isRemoving) return uniq(removingCardIds.concat(cardId));

      return removingCardIds.filter(
        (removingCardId) => removingCardId !== cardId,
      );
    });
  };

  const removeCard = async (cardId: string) => {
    try {
      setCardIsRemoving(cardId, true);

      await deleteACard(cardId);

      // @ts-ignore-line
      mutate((paymentMethods) => {
        const newPaymentMethods = paymentMethods?.data?.data.filter(
          (paymentMethod) => paymentMethod.id !== cardId,
        );

        return newPaymentMethods;
      });
    } catch (err) {
      throw err;
    } finally {
      setCardIsRemoving(cardId, false);
    }
  };

  const addCard = async (
    clientSecret: string,
    { payment_method }: ConfirmCardSetupData,
  ) => {
    const response = await confirmCardSetup(clientSecret, {
      payment_method,
    });

    const cards = await mutate(); // manually trigger a re-fetch of user's card list
    // cannot update state directly because confirmCardPayment does not return the added card

    // handle duplicate card
    // currently Stripe does not have a built-in way to prevent adding a duplicate card (ref: https://github.com/stripe/stripe-payments-demo/issues/45)
    // and we only know the card fingerprint (which is used to identify unique card) only after the card is setup
    // therefore we could only let the duplicate card to be added, identify it as a duplicate, and then remove it
    const duplicateCards = getDuplicateCards(cards?.data?.data ?? []);

    const hasDuplicateCards = !isEmpty(duplicateCards);

    if (hasDuplicateCards) {
      await Promise.all(
        duplicateCards.map((card) => removeCard(card.id)),
      );

      return {
        error: {
          message: 'This card have already been added. ',
        },
      };
    }

    return response;
  };

  return {
    cards,
    isLoading,
    error,
    mutate,
    addCard,
    isCardBeingRemoved,
    getCardInput,
    removeCard,
  };
};

export default useUserCards;
