import * as api from '../api';

class UserCart {
  async getAsync() {
    try {
      const userCart = await api.getUserCart();

      return userCart;
    } catch (err) {
      throw err;
    }
  }

  // Shouldn't be used manually now since getUserCart API endpoint
  // will create a new cart for the user if the user have no cart yet
  createAsync() {
    return api.createCart();
  }
}

export default UserCart;
