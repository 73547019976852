import { useEffect } from 'react';

const useFontLoader = () => {
  useEffect(() => {
    const WebFont = require('webfontloader');

    WebFont.load({
      google: {
        families: [
          'Montserrat:100,200,300,400,500,600,700,800,900,100,200,300,400,500,600,700,800,900',
        ],
      },
    });
  }, []);
};

export default useFontLoader;
