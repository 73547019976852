import React, { ComponentPropsWithRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from '../Spinner';

export enum BUTTON_VARIANT {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  LINK = 'link',
}
export type ButtonVariantString = `${BUTTON_VARIANT}`;

export enum BUTTON_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  LIGHT = 'light',
  DARK = 'dark',
}
export type ButtonColorString = `${BUTTON_COLOR}`;

export enum BUTTON_SHAPE {
  ROUNDED_CORNERS = 'rounded-corners',
  SQUARE = 'square',
  ROUND = 'round',
}
export type ButtonShapeString = `${BUTTON_SHAPE}`;

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  variant?: BUTTON_VARIANT | ButtonVariantString;
  color?: BUTTON_COLOR | ButtonColorString;
  shape?: BUTTON_SHAPE | ButtonShapeString;
  children?: JSX.Element;
  loading?: boolean;
  block?: boolean;
  disabled?: boolean;
  renderLoading?: (children: JSX.Element) => JSX.Element;
  className?: string;
}

const Button = ({
  variant,
  color,
  shape,
  children,
  loading,
  block,
  disabled,
  renderLoading,
  className,
  ...others
}: ButtonProps) => {
  return (
    <button
      {...others}
      disabled={loading || disabled}
      className={classNames('btn', className, {
        'btn-primary':
          color === BUTTON_COLOR.PRIMARY &&
          variant === BUTTON_VARIANT.CONTAINED,
        'btn-secondary':
          color === BUTTON_COLOR.SECONDARY &&
          variant === BUTTON_VARIANT.CONTAINED,
        'btn-light':
          color === BUTTON_COLOR.LIGHT &&
          variant === BUTTON_VARIANT.CONTAINED,
        'btn-dark':
          color === BUTTON_COLOR.DARK &&
          variant === BUTTON_VARIANT.CONTAINED,
        'btn-outline-primary-2':
          color === BUTTON_COLOR.PRIMARY &&
          variant === BUTTON_VARIANT.OUTLINED,
        'btn-outline-secondary-2':
          color === BUTTON_COLOR.SECONDARY &&
          variant === BUTTON_VARIANT.OUTLINED,
        'btn-outline-light-2':
          color === BUTTON_COLOR.DARK &&
          variant === BUTTON_VARIANT.OUTLINED,
        'btn-outline-dark-2':
          color === BUTTON_COLOR.DARK &&
          variant === BUTTON_VARIANT.OUTLINED,
        'btn-rounded': shape === BUTTON_SHAPE.ROUNDED_CORNERS,
        'btn-round': shape === BUTTON_SHAPE.ROUND,
        'btn-danger':
          color === BUTTON_COLOR.DANGER &&
          variant === BUTTON_VARIANT.CONTAINED,
        'btn-outline-danger':
          color === BUTTON_COLOR.DANGER &&
          variant === BUTTON_VARIANT.OUTLINED,
        'btn-link': variant === BUTTON_VARIANT.LINK,
        'btn-block': block,
      })}
    >
      {loading ? renderLoading!(children!) : children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  color: PropTypes.oneOf(Object.values(BUTTON_COLOR)),
  shape: PropTypes.oneOf(Object.values(BUTTON_SHAPE)),
  loading: PropTypes.bool,
  block: PropTypes.bool,
  renderLoading: PropTypes.func,
};

Button.defaultProps = {
  variant: BUTTON_VARIANT.CONTAINED,
  color: BUTTON_COLOR.PRIMARY,
  shape: BUTTON_SHAPE.SQUARE,
  loading: false,
  block: false,
  renderLoading: (children: JSX.Element) => (
    <React.Fragment>
      <Spinner size="sm" />
      <span>&nbsp;&nbsp;</span>
      {children}
    </React.Fragment>
  ),
};

export { Button };
