import { useCRUD } from 'lib/swr-crud';
import { getUserById } from './api';

const useUser = (userId) => {
  const { data, error, mutate, isValidating, isLoading } = useCRUD(
    `/users/${userId}`,
    async () => {
      if (userId) {
        const response = await getUserById(userId);
        return response;
      }
    },
  );

  const user = data || null;

  return {
    user,
    error,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useUser;
