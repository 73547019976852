import { useListCRUD } from 'lib/swr-crud';
import { getCategories } from './api';
import { makeCategoriesCacheKey } from './utils';

const fetchers = {
  read: async (_url) => {
    const { response, error } = await getCategories();

    if (error) throw error;

    return response.data;
  },
};

const useCategories = (options = {}) => {
  const { data, error, create, isLoading } = useListCRUD(
    makeCategoriesCacheKey(),
    'id',
    {
      ...options,
      fetchers,
    },
  );

  const categories = data || [];

  return {
    categories,
    create,
    error,
    isLoading,
  };
};

export default useCategories;
