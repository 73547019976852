import React from 'react';

import Link from 'components/common/link';
import { Button } from 'components/common/Button';

import { routePaths } from 'appConfig';

import { useAuth, redirectToLoginPage } from 'lib/auth';

const User = () => {
  const { session, loading, loggingOut, signOut } = useAuth();

  if (loading) return <p>Loading...</p>;

  if (!session)
    return (
      <Button
        onClick={redirectToLoginPage}
        variant="link"
        style={{ whiteSpace: 'nowrap' }}
      >
        Sign in / Register
      </Button>
    );

  return (
    <React.Fragment>
      <p className="pb-1" style={{ width: '320px' }}>
        Signed in as <strong>{session?.user?.email}</strong>
      </p>
      <div className="tw-flex tw-items-center tw-justify-between">
        <Button
          variant="link"
          color="secondary"
          style={{ paddingTop: '0.85rem' }}
        >
          <Link to={routePaths.ACCOUNT}>MY ACCOUNT</Link>
        </Button>
        <Button
          style={{ backgroundColor: '#911d1e', color: 'white' }}
          variant="outlined"
          onClick={signOut}
          disabled={loggingOut}
          loading={loggingOut}
        >
          SIGN OUT
        </Button>
      </div>
    </React.Fragment>
  );
};

export default User;
