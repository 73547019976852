import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import Link from 'components/common/link';
import Logo from 'components/common/Logo';
import {
  EqualColsGrid,
  IconBox,
  Icon,
  Button,
} from 'components/common';
import { useCategories } from 'lib/productCategory';
import { AcceptedPaymentMethods } from 'lib/payment';
import { chunk } from 'lib/javascript';

import config, { routePaths } from 'appConfig';
import { redirectToLoginPage, useAuth } from 'lib/auth';

const feature_boxes = [
  {
    icon_set: 'custom',
    icon: 'calendar',
    title: 'WE DELIVER ON',
    text: 'Mon & Thu: 10am-1pm & 7pm-10pm<br>Wed & Sun: 7pm-10pm',
  },
  {
    icon_set: 'custom',
    icon: 'stopwatch',
    title: 'PLEASE ORDER BY',
    text:
      'Tue 6pm for Wed/Thu Delivery<br>Fri 6pm for Sun/Mon Delivery',
  },
  {
    icon_set: 'custom',
    icon: 'stopwatch-fast',
    title: 'ORDER IN ADVANCE',
    text: 'Up to 1 month prior to delivery date',
  },
];

const ICON_SIZE = 36;

const informationItems = [
  {
    to: routePaths.OUR_STORY,
    label: 'Our Story',
  },
  {
    to: routePaths.HOW_TO_ORDER,
    label: 'How to Order',
  },
  {
    to: routePaths.DOORSTEP_DELIVERY,
    label: 'Doorstep Delivery',
  },
  {
    to: routePaths.CLICK_AND_COLLECT,
    label: 'Click and Collect',
  },
  {
    to: routePaths.MEALCARE,
    label: 'Meal Care',
  },
  {
    to: routePaths.IS_YUMMY_BROS_HALAL,
    label: 'Is Yummy Bros Halal?',
  },
  {
    to: routePaths.YB_REPS,
    label: 'Loyalty Programme',
  },
  {
    to: routePaths.CONTACT_US,
    label: 'Contact Us',
  },
];

const commonMyAccountItems = [
  {
    to: '/cart',
    label: 'View Cart',
  },
];

const authMyAccountItems = [
  {
    to: routePaths.ACCOUNT,
    label: 'Account Details',
  },
  ...commonMyAccountItems,
  {
    to: '/account?tab=referAFriend',
    label: 'Refer a Friend',
  },
];

const guestMyAccountItems = [
  {
    onClick: redirectToLoginPage,
    label: 'Log in',
  },
  ...commonMyAccountItems,
];

function Footer(props) {
  const { container = 'container' } = props;
  const { session } = useAuth();
  const { categories, isLoading } = useCategories();
  // TODO: think of ways to implement SSR for "Order Now" section.
  // ref: https://github.com/vercel/next.js/discussions/10949#discussioncomment-326570
  // ref: https://github.com/vercel/next.js/discussions/10949#discussioncomment-110524

  const [cappedCategories, ...otherCategories] = isLoading
    ? []
    : chunk(categories, 7);

  const exceededCategoriesCap = otherCategories?.length > 0;

  const _myAccountItems = session
    ? authMyAccountItems
    : guestMyAccountItems;

  return (
    <footer className="footer footer-2">
      <div className="footer-top mt-5 mb-3">
        <Container>
          <EqualColsGrid cols={3}>
            {feature_boxes.map(
              ({ icon_set, icon, title, text }, index) => (
                <IconBox
                  key={index}
                  theme="dark"
                  boxStyle="icon-box-left"
                  icon={
                    <Icon
                      iconSet={icon_set}
                      name={icon}
                      size={ICON_SIZE}
                      style={{ color: '#FFF' }}
                    />
                  }
                  title={title}
                  text={text}
                />
              ),
            )}
          </EqualColsGrid>
        </Container>
      </div>
      <hr className="m-0" style={{ borderColor: '#444' }} />
      <div className="footer-middle border-0">
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <div className="widget widget-about">
                <div className="footer-logo">
                  <Logo variant="logo-horizontal" theme="dark" />
                </div>

                <p>
                  The best diet is an enjoyable one. That's why we
                  reinvent popular Asian dishes, loved by millions
                  around the world, to be healthier & customisable
                  while kept as flavourful as before. Order a week's
                  supply of meals, store in your freezer, and reheat
                  whenever you need to eat.{' '}
                </p>

                <div className="widget-about-info">
                  <AcceptedPaymentMethods
                    className="footer-payments"
                    acceptedPaymentMethods={
                      config.acceptedPaymentMethods
                    }
                  />
                </div>
              </div>
            </Col>

            <Col sm={4} lg={2}>
              <div className="widget">
                <h4 className="widget-title">Information</h4>

                <ul className="widget-list">
                  {informationItems.map(({ to, label }) => (
                    <li>
                      <Link to={to}>{label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>

            <Col sm={4} lg={2}>
              <div className="widget">
                <h4 className="widget-title">Order Now</h4>

                <ul className="widget-list">
                  {isLoading && 'Loading...'}
                  {!isLoading &&
                    (cappedCategories || []).map((category) => (
                      <li key={category.id}>
                        <Link
                          to={`${routePaths.PRODUCTS}?category_ids=${category.id}`}
                        >
                          {category.name}
                        </Link>
                      </li>
                    ))}
                  {exceededCategoriesCap && (
                    <li>
                      <Link to={routePaths.CATEGORIES}>More...</Link>
                    </li>
                  )}
                </ul>
              </div>
            </Col>

            <Col sm={4} lg={2}>
              <div className="widget">
                <h4 className="widget-title">My Account</h4>

                <ul className="widget-list">
                  {_myAccountItems.map(({ to, onClick, label }) => (
                    <li key={`${to}-${label}`}>
                      {onClick ? (
                        <Button
                          variant="outlined"
                          shape="rounded-corners"
                          onClick={onClick}
                        >
                          {label}
                        </Button>
                      ) : (
                        <Link to={to}>{label}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer-bottom">
        <div className={container}>
          <p className="footer-copyright">
            Copyright © {new Date().getFullYear()} {config.appName}.
            All Rights Reserved.
          </p>
          <ul className="footer-menu">
            <li>
              <Link to="/pages/terms-of-use">Terms Of Use</Link>
            </li>
          </ul>

          <div className="social-icons social-icons-color">
            <span className="social-label">Social Media</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {config.socialMedias.map(
                ({ url, icon, iconSet, platform, className }) => (
                  <a
                    key={url}
                    href={url}
                    className={classNames('social-icon', className)}
                    rel="noopener noreferrer"
                    title={platform}
                    target="_blank"
                  >
                    <Icon iconSet={iconSet} name={icon} />
                    <i className={icon}></i>
                    <span style={{ marginLeft: '8px' }}>
                      {platform}
                    </span>
                  </a>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
