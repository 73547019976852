import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip as RBTooltip,
  OverlayTrigger,
} from 'react-bootstrap';

const Tooltip = ({ children, placement, content, id, trigger }) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={<RBTooltip id={id}>{content}</RBTooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  content: PropTypes.node.isRequired,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Tooltip;
