import { useState, useEffect, CSSProperties, HTMLProps } from 'react';
import PropTypes from 'prop-types';
import IcoMoon from 'react-icomoon';

type ParentProps = HTMLProps<HTMLDivElement>;

type Props = {
  [Property in keyof ParentProps]?: ParentProps[Property];
} & {
  name: string;
  fontSize?: CSSProperties['fontSize'];
  style?: CSSProperties;
};

const CustomIcon = ({ name, fontSize, style, ...props }: Props) => {
  const [iconSet, setIconSet] = useState<any>(null);

  useEffect(() => {
    const loadIconSet = async () => {
      const customIconSet = await import('./custom.json');

      setIconSet(customIconSet);
    };

    loadIconSet();
  }, []);

  if (!iconSet) return null;

  return (
    <IcoMoon
      iconSet={iconSet}
      icon={name}
      size={fontSize}
      style={style}
      disableFill
      {...props}
    />
  );
};

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

export { CustomIcon };
