import { useEffect } from 'react';
import { signOut } from 'next-auth/client';

import useAuth from './useAuth';
import { ERROR_CODE } from './constants';

const useClearSessionOnRefreshTokenError = () => {
  const { session } = useAuth();

  useEffect(() => {
    if (session?.error === ERROR_CODE.REFRESH_TOKEN) {
      signOut({ redirect: false });
    }
  }, [session]);
};

export default useClearSessionOnRefreshTokenError;
