import { routePaths } from 'appConfig';
import { ReactNode } from 'react';

interface MenuItem {
  to: string;
  label: ReactNode;
}

export const cateringItems: MenuItem[] = [
  {
    to: routePaths.BUFFET,
    label: 'Buffet Catering',
  },
  {
    to: routePaths.WARM_BENTO_CATERING,
    label: 'Warm-Served Bentos',
  },
];

export const aboutUsItems: MenuItem[] = [
  {
    to: routePaths.OUR_STORY,
    label: 'Our Story',
  },
  {
    to: routePaths.CAUSES_WE_SUPPORTED,
    label: 'Causes We’ve Supported',
  },
  {
    to: routePaths.CONTACT_US,
    label: 'Contact Us',
  },
];

export const mealPreps: MenuItem[] = [
  {
    to: routePaths.HOW_TO_ORDER,
    label: 'How to Order',
  },
  {
    to: routePaths.DOORSTEP_DELIVERY,
    label: 'Doorstep Delivery',
  },
  {
    to: routePaths.CLICK_AND_COLLECT,
    label: 'Click and Collect',
  },
  {
    to: routePaths.MEALCARE,
    label: 'Meal Care Instructions',
  },
  {
    to: routePaths.WEIGHT_LOSS_TIPS,
    label: 'Weight Loss Tips',
  },
  {
    to: routePaths.IS_YUMMY_BROS_HALAL,
    label: 'Is Yummy Bros Halal?',
  },
  {
    to: routePaths.YB_REPS,
    label: 'Loyalty Programme',
  },
  {
    to: routePaths.MY_FITNESS_PAL,
    label: 'My Fitness Pal',
  },
  {
    to: routePaths.WEEKLY_MEAL_PLANNER,
    label: 'Weekly Meal Planner',
  },
  {
    to: routePaths.GIFT_CARDS,
    label: 'Buy Gift Cards',
  },
];
