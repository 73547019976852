export const getStoreConfig = async () => {
  const config = (await import('appConfig')).default;

  const { timezone, locale, currency, location } = config;

  const storeConfig = {
    timezone,
    locale,
    currency,
    location,
  };

  return storeConfig;
};
