import { createContext, Component, useContext } from 'react';

export const applyCouponCodeFormContextDefaultValues = {
  values: {
    coupon_code: '',
  },
  errors: {},
  setValues: () => {},
  setErrors: () => {},
  setCouponCode: () => {},
  setCouponCodeError: () => {},
};

export const ApplyCouponCodeFormContext = createContext(
  applyCouponCodeFormContextDefaultValues,
);

export class ApplyCouponCodeFormContextProvider extends Component {
  constructor(props) {
    super(props);

    this.setValues = (values) => this.setState({ values });

    this.setCouponCode = (couponCode) =>
      this.setValues({ coupon_code: couponCode });

    this.setErrors = (errors) => this.setState({ errors });

    this.setCouponCodeError = (couponCodeError) =>
      this.setErrors({ coupon_code: couponCodeError });

    this.state = {
      values: applyCouponCodeFormContextDefaultValues.values,
      errors: applyCouponCodeFormContextDefaultValues.errors,
      setValues: this.setValues,
      setErrors: this.setErrors,
      setCouponCode: this.setCouponCode,
      setCouponCodeError: this.setCouponCodeError,
    };
  }

  render() {
    const { children } = this.props;

    return (
      <ApplyCouponCodeFormContext.Provider value={this.state}>
        {children}
      </ApplyCouponCodeFormContext.Provider>
    );
  }
}

export const ApplyCouponCodeFormContextConsumer =
  ApplyCouponCodeFormContext.Consumer;

export const useApplyCouponCodeFormContext = () => {
  const contextValues = useContext(ApplyCouponCodeFormContext);

  return contextValues;
};
