import { loadLogger } from 'lib/dynamicModules';

export const getHttpClientLogger = async () => {
  const logger = await loadLogger();

  const httpClientLogger = logger.child({ event: { tag: 'api' } });

  return httpClientLogger;
};

const CONFIG_USER_KEY = '__user';

export const setUserToHttpClientConfig = (config, user) => {
  config[CONFIG_USER_KEY] = user;
};

export const getUserFromHttpClientConfig = (config) =>
  config[CONFIG_USER_KEY];
