import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSession } from 'next-auth/client';
import { AUTH_STATUS } from './constants';

const AuthStatusContext = createContext({
  authStatus: AUTH_STATUS.LOADING,
  setAuthStatus: () => {},
});

export const AuthStatusContextProvider = ({ children }) => {
  const [session] = useSession();
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.LOADING);

  useEffect(() => {
    setAuthStatus(
      session
        ? AUTH_STATUS.AUTHENTICATED
        : AUTH_STATUS.UNAUTHENTICATED,
    );
  }, [session]);

  useEffect(() => {
    switch (authStatus) {
      case AUTH_STATUS.SIGN_IN_SUCCESS:
      case AUTH_STATUS.SIGN_OUT_FAILED:
        setAuthStatus(AUTH_STATUS.AUTHENTICATED);
        break;
      case AUTH_STATUS.SIGN_IN_FAILED:
      case AUTH_STATUS.SIGN_OUT_SUCCESS:
        setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
        break;
      default:
    }
  }, [authStatus]);

  return (
    <AuthStatusContext.Provider value={{ authStatus, setAuthStatus }}>
      {children}
    </AuthStatusContext.Provider>
  );
};

export const useAuthStatus = () => {
  return useContext(AuthStatusContext);
};
