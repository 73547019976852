import React, { useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Link from 'components/common/link';

// Common Header Components
import MainMenu from './partials/main-menu';
// import SearchForm from './partials/search-form';
import UserMenu from './partials/user-menu';
import CartMenu from './partials/cart-menu';
import { useDeliverySlotsContext } from 'lib/deliveryDateTimes';
import { CountDownTimer } from 'lib/datetime/components';

const styles = {
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Header(props) {
  const { container = 'container' } = props;
  const {
    nextCutOffTime,
    refreshCutOffTime,
  } = useDeliverySlotsContext();
  const [showCutOffTimeBanner, setShowCutOffTimeBanner] = useState(
    true,
  );

  const _refreshCutOffTimeSlot = useCallback(
    (cb) => {
      refreshCutOffTime();
      cb && cb();
    },
    [refreshCutOffTime],
  );

  return (
    <>
      {showCutOffTimeBanner && (
        <div className="notification">
          <div className="notify-content">
            <h3>
              Orders for next delivery will cut off in{' '}
              <span className="timer">
                {
                  <CountDownTimer
                    value={nextCutOffTime}
                    onComplete={_refreshCutOffTimeSlot}
                  />
                }
              </span>
            </h3>
          </div>
          <div className="notify-action">
            <Link to="#">
              <i
                className="icon-close"
                onClick={() => setShowCutOffTimeBanner(false)}
              ></i>
            </Link>
          </div>
        </div>
      )}

      <header className="header">
        <div className="header-bottom sticky-header">
          <div className={container} style={{ height: '80px' }}>
            <div className="header-left">
              <button className="mobile-menu-toggler">
                <span className="sr-only">Toggle mobile menu</span>
                <i className="icon-bars"></i>
              </button>

              <Link to={'/'} className="logo">
                <Logo variant="logo-horizontal" />
              </Link>
            </div>

            <div className="header-center">
              <MainMenu />
            </div>

            <div className="header-right">
              <Container fluid style={{ padding: 0 }}>
                <Row>
                  {/* <Col style={styles.centered}>
                    <SearchForm />
                  </Col> */}
                  <Col style={styles.centered}>
                    <UserMenu />
                  </Col>
                  <Col style={styles.centered}>
                    <CartMenu />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
