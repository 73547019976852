import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { times } from 'lib/javascript';

const TYPE = {
  LINE: 'line',
  TABLE: 'table',
  CUSTOM: 'custom',
};

const OPTION_KEY = {
  NUMBER_OF_LINES: 'numberOfLines',
  LINE_HEIGHT: 'lineHeight',
  LINES_GAP: 'linesGap',
};

const Skeleton = ({
  type,
  options,
  style,
  width,
  height,
  children,
}) => {
  const getOptionDefaultValue = (optionKey) => {
    switch (optionKey) {
      case OPTION_KEY.NUMBER_OF_LINES:
        return 1;

      case OPTION_KEY.LINE_HEIGHT:
        return 24;

      case OPTION_KEY.LINES_GAP:
        return 16;

      default:
        return null;
    }
  };

  const getOptionValue = (optionKey) =>
    options[optionKey] || getOptionDefaultValue(optionKey);

  const getLineHeight = () => getOptionValue(OPTION_KEY.LINE_HEIGHT);

  const getLinesGap = () => getOptionValue(OPTION_KEY.LINES_GAP);

  const getHeight = () => {
    switch (type) {
      case TYPE.LINE:
        const numberOfLines = getOptionValue(
          OPTION_KEY.NUMBER_OF_LINES,
        );

        return (
          numberOfLines * getLineHeight() +
          (numberOfLines - 1) * getLinesGap()
        );

      default:
        return height;
    }
  };

  const getWidth = () => {
    return width || '100%';
  };

  const genLine = ({
    numberOfLines,
    linesGap = getLinesGap(),
    lineHeight = getLineHeight(),
  }) => {
    const LINES_TOP_SPACE = 0;

    return times(numberOfLines).map((_, index) => (
      <rect
        key={index}
        x="0"
        y={LINES_TOP_SPACE + index * (linesGap + lineHeight)}
        rx="0"
        ry="0"
        width="680"
        height={lineHeight}
      />
    ));
  };

  const getSvg = () => {
    switch (type) {
      case TYPE.LINE:
        return genLine({
          numberOfLines: getOptionValue(OPTION_KEY.NUMBER_OF_LINES),
          lineHeight: getLineHeight(),
          linesGap: getLinesGap(),
        });

      case TYPE.TABLE:
        return genLine({ numberOfLines: 3 });

      default:
        return children;
    }
  };

  return (
    <ContentLoader
      height={getHeight()}
      width={getWidth()}
      viewBox={`0 0 ${getWidth()} ${getHeight()}`}
      style={{ width: getWidth(), ...style }}
    >
      {getSvg()}
    </ContentLoader>
  );
};

Skeleton.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPE)),
  options: PropTypes.shape({
    numberOfLines: PropTypes.number,
    lineHeight: PropTypes.number,
    linesGap: PropTypes.number,
  }),
  style: PropTypes.object,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Skeleton.defaultProps = {
  type: TYPE.LINE,
  options: {},
  style: {},
};

export default Skeleton;
