import * as fbq from 'lib/fb-pixel';

import { getDisplayingProductVariant } from 'lib/product';
import { utils } from 'lib/productVariants';
import { getCartLineUnitPrice } from 'lib/cart';
import { getAppliedCoupons } from 'lib/order';

import config from 'appConfig';
import { ecommerce } from 'lib/gtm';

const toGTMItemType = ({ product, productVariant, quantity }) => {
  const { id: itemId, name: itemName, categories, options } = product;
  const { price } = productVariant;

  const itemCategory = categories?.[0]?.name;
  const itemVariant = utils.makeVariantName(productVariant, options);

  return {
    itemId,
    itemName,
    itemCategory,
    itemVariant,
    price: Number(price),
    quantity,
  };
};

export const addPaymentInfo = () => {
  fbq.addPaymentInfo();
};

export const addToCart = ({
  product,
  productVariant,
  quantity,
  updatedCart,
}) => {
  fbq.addToCart({
    content_ids: [productVariant.id],
    content_type: 'product',
    value: productVariant.price,
    currency: config.currency,
  });

  const { sub_total } = updatedCart;
  ecommerce.addToCart({
    value: sub_total,
    item: toGTMItemType({ product, productVariant, quantity }),
  });
};

export const removeFromCart = ({
  product,
  productVariant,
  quantity,
  total,
}) => {
  ecommerce.removeFromCart({
    value: total,
    item: toGTMItemType({ product, productVariant, quantity }),
  });
};

export const completeRegistration = ({ isSuccess }) => {
  fbq.completeRegistration({ status: isSuccess });
};

export const contact = () => {
  fbq.contact();
};

export const customizeProduct = () => {
  fbq.customizeProduct();
};

export const initiateCheckout = ({ cart }) => {
  fbq.initiateCheckout({
    content_ids: cart.items.map(
      (cartLine) => cartLine.product_variant_id,
    ),
    num_items: cart.quantity,
    value: cart.sub_total,
    currency: config.currency,
  });

  const items = cart.items.map((item) => {
    const { product, product_variant_id, quantity } = item;
    const productVariant = product.variants.find(
      (variant) => variant.id === product_variant_id,
    );

    const { category, variant } = productVariantToGAProduct({
      product,
      productVariant,
    });

    return {
      itemId: product.id,
      itemName: product.name,
      itemCategory: category,
      itemVariant: variant,
      price: getCartLineUnitPrice(item),
      quantity,
    };
  });

  ecommerce.beginCheckout({ value: cart.sub_total, items });
};

export const lead = () => {
  fbq.lead();
};

export const pageView = () => {
  fbq.pageView();
};

export const purchase = ({ order, cart }) => {
  fbq.purchase({
    content_ids: order.lines.map(
      (orderLine) => orderLine.product_variant_id,
    ),
    value: order.grand_total,
    currency: config.currency,
  });

  const [coupon] = getAppliedCoupons(order);
  const items = cart.items.map((item) => {
    const { product, product_variant_id, quantity } = item;
    const productVariant = product.variants.find(
      (variant) => variant.id === product_variant_id,
    );

    const { category, variant } = productVariantToGAProduct({
      product,
      productVariant,
    });

    return {
      itemId: product.id,
      itemName: product.name,
      itemCategory: category,
      itemVariant: variant,
      price: getCartLineUnitPrice(item),
      quantity,
    };
  });

  ecommerce.purchase({
    transactionId: order.id,
    shipping: order.delivery_total,
    coupon: coupon?.coupon_code,
    value: order.grand_total,
    tax: order.tax_total,
    items,
  });
};

export const viewCart = ({ cart }) => {
  const items = cart.items.map((item) => {
    const { product, product_variant_id, quantity } = item;
    const productVariant = product.variants.find(
      (variant) => variant.id === product_variant_id,
    );

    const { category, variant } = productVariantToGAProduct({
      product,
      productVariant,
    });

    return {
      itemId: product.id,
      itemName: product.name,
      itemCategory: category,
      itemVariant: variant,
      price: getCartLineUnitPrice(item),
      quantity,
    };
  });

  ecommerce.viewCart({ value: cart.sub_total, items });
};

export const search = ({ searchString }) => {
  fbq.search({
    search_string: searchString,
  });
};

export const viewProduct = ({ product }) => {
  const displayingVariant = getDisplayingProductVariant(product);

  fbq.viewContent({
    content_ids: [product.id],
    content_type: 'product_group',
    value: displayingVariant.price,
    currency: config.currency,
  });

  ecommerce.viewItem({
    value: displayingVariant.price,
    item: {
      itemId: product.id,
      itemName: product.name,
      itemCategory: product.categories?.[0]?.name,
      price: Number(displayingVariant.price),
    },
  });
};

export const viewProducts = ({ category, products }) => {
  const items = products.map((product) => {
    const displayingVariant = getDisplayingProductVariant(product);

    return {
      itemId: product.id,
      itemName: product.name,
      itemCategory: product.categories?.[0]?.name,
      price: Number(displayingVariant.price),
    };
  });

  ecommerce.viewItems({
    itemListId: category?.id,
    itemListName: category?.name,
    items,
  });
};

export const viewProductVariant = ({ product, productVariant }) => {
  const { id, price } = productVariant;

  fbq.viewContent({
    content_ids: [id],
    content_type: 'product',
    value: price,
    currency: config.currency,
  });
};

export const productToGAProduct = (product) => {
  const { id, name, categories, options } = product;

  const displayingVariant = getDisplayingProductVariant(product);

  return {
    id,
    name,
    category: categories?.[0]?.name,
    variant: utils.makeVariantName(displayingVariant, options),
  };
};

export const productVariantToGAProduct = ({
  product,
  productVariant,
}) => {
  const { id, name, categories, options } = product;

  return {
    id,
    name,
    category: categories?.[0]?.name,
    variant: utils.makeVariantName(productVariant, options),
  };
};
