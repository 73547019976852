import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const postRegistration = async ({
  firstName,
  lastName,
  phoneNumber,
  email,
  password,
  is_subscribe_to_mailchimp,
}) => {
  try {
    const response = await httpClient.post('/auth/register', {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email,
      password,
      is_subscribe_to_mailchimp,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const postJwtLogin = async (data) => {
  try {
    const response = await httpClient.post('/auth/access', data);

    if (response.status === 400 || response.status === 500)
      return { error: response.data };

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const refreshJwtToken = async (token) => {
  try {
    const response = await httpClient({
      method: 'post',
      url: '/auth/refresh',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      skipAuthRefresh: true,
    });
    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const postJwtLogout = async (token) => {
  try {
    const response = await httpClient({
      method: 'post',
      url: '/auth/logout',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { response: getResponseData(response) };
  } catch (error) {
    return { error };
  }
};

export const getVerifyResetToken = async ({ email, token }) => {
  try {
    const response = await httpClient.get('/auth/password/confirm', {
      params: {
        email,
        token,
      },
    });

    const data = getResponseData(response);

    if (data.message === 'invalid') throw new Error('Invalid Token');

    return { response: data };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const postSendResetPasswordEmail = async ({ email }) => {
  try {
    const response = await httpClient.post('/auth/password/reset', {
      email,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const postResetPassword = async ({
  email,
  password,
  token,
}) => {
  try {
    const response = await httpClient.post('/auth/password/confirm', {
      email,
      password,
      token,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
