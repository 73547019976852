import React from 'react';

import { OverlayTrigger } from 'components/common';
import useMediaQuery from 'hooks/useMediaQuery';
import { CartQuickView, useCart, useCheckoutCart } from 'lib/cart';
import { Price } from 'lib/core/components';

import Skeleton from '../Skeleton';

function CartMenu() {
  const {
    cart,
    loading,
    error,
    isRemovingCartLine,
    removeCartLine,
  } = useCart();

  const { checkout } = useCheckoutCart(cart);

  const { items, sub_total, quantity } = cart ?? {};

  const isViewPortSmall = useMediaQuery('(max-width: 480px)');
  const isPointerCoarse = useMediaQuery('(pointer: coarse)');

  const isMobile = isViewPortSmall && isPointerCoarse;

  const cartlist = items || [];
  let total = sub_total;
  const count = quantity;

  const [
    shouldShowCartQuickView,
    setShouldShowCartQuickView,
  ] = React.useState(false);

  const hideCartQuickView = () => setShouldShowCartQuickView(false);

  if (isMobile)
    return (
      <div className="dropdown cart-dropdown">
        <OverlayTrigger
          show={shouldShowCartQuickView}
          onToggle={setShouldShowCartQuickView}
          trigger={['click', 'focus']}
          placement="bottom-end"
          rootClose
          overlay={
            <div
              className="dropdown cart-dropdown"
              style={{ zIndex: 999 }}
            >
              <div
                id="div-cart-view-wrapper"
                className="dropdown-menu dropdown-menu-right"
              >
                <CartQuickView
                  loading={loading}
                  error={error}
                  cartlist={cartlist}
                  total={total}
                  isRemovingCartLine={isRemovingCartLine}
                  removeFromCart={removeCartLine}
                  onClickViewCart={hideCartQuickView}
                  onClickCheckout={() => {
                    checkout();
                    hideCartQuickView();
                  }}
                />
              </div>
            </div>
          }
        >
          <div
            className="dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-display="static"
          >
            <i className="icon-shopping-cart"></i>
            {!loading && (
              <span className="cart-count">
                {error ? '!' : count}
              </span>
            )}
            &nbsp;
            {loading ? (
              <Skeleton width={38} options={{ lineHeight: 13 }} />
            ) : (
              <span className="cart-txt">
                <Price value={total} />
              </span>
            )}
          </div>
        </OverlayTrigger>
      </div>
    );

  return (
    <div className="dropdown cart-dropdown">
      <div
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-display="static"
      >
        <i className="icon-shopping-cart"></i>
        {!loading && (
          <span className="cart-count">{error ? '!' : count}</span>
        )}
        &nbsp;
        {loading ? (
          <Skeleton width={38} options={{ lineHeight: 13 }} />
        ) : (
          <span className="cart-txt">
            <Price value={total} />
          </span>
        )}
      </div>

      <div className={'dropdown-menu dropdown-menu-right'}>
        <CartQuickView
          loading={loading}
          error={error}
          cartlist={cartlist}
          total={total}
          isRemovingCartLine={isRemovingCartLine}
          removeFromCart={removeCartLine}
          onClickCheckout={checkout}
        />
      </div>
    </div>
  );
}

export default CartMenu;
