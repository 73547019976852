import { useAuth } from 'lib/auth';
import { LoveMessage, LoveMessagesQueryParams } from './types';
import { useGet } from 'lib/swr-crud';
import { APIErrorResponse, APIListResponse } from 'lib/utils';
import { AxiosError } from 'axios';
import { dismiss, getLoveMessages, getLoveMessagesUrl } from './api';
import { isNil } from 'lib/javascript';

const getUnreadLoveMessage = (
  loveMessages: LoveMessage[],
): LoveMessage | null => {
  if (!loveMessages.length) return null;

  const unreadLoveMessages = loveMessages.filter((loveMessage) =>
    isNil(loveMessage.dismissed_at),
  );

  if (unreadLoveMessages.length > 0) {
    return unreadLoveMessages[0];
  }

  return null;
};

const useUserLoveMessages = ({
  page,
  perPage,
}: LoveMessagesQueryParams) => {
  const { session, loading } = useAuth();

  const userId = session?.user?.userId;
  const shouldFetch = !loading && userId !== undefined;

  const { data, isLoading, mutate } = useGet<
    APIListResponse<LoveMessage>,
    AxiosError<APIErrorResponse>
  >(
    shouldFetch
      ? getLoveMessagesUrl(userId, { page, perPage })
      : null,
    async () =>
      await getLoveMessages(userId as string, { page, perPage }),
  );

  const total: number = data?.meta?.total ?? 0;
  const loveMessages = data?.data ?? [];

  const unreadLoveMessage: LoveMessage | null = getUnreadLoveMessage(
    loveMessages,
  );

  const dismissLoveMessage = async (loveMessageId: string) => {
    await dismiss(userId as string, loveMessageId);
    mutate();
  };

  return {
    loveMessages,
    unreadLoveMessage,
    isLoading,
    total,
    mutate,
    dismissLoveMessage,
  };
};

export default useUserLoveMessages;
