const getErrorMessage = (err) => {
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const backendErrors = err.response.data;

    return backendErrors;
  } else if (err.request) {
    return err.message;
  } else {
    // Something happened in setting up the request that triggered an Error
    return err.message;
  }
};

export default getErrorMessage;
