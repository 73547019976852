import React, { Component } from 'react';

import { Button } from 'components/common/Button';
import Modal from 'components/common/Modal';
import { merge } from 'lib/javascript';

import ConfirmationContext from './ConfirmationContext';

const defaultOptions = {
  title: 'Confirmation Dialog',
  content: 'This action is irreversible. Are you sure?',
  buttonLabels: {
    yes: 'OK',
    no: 'Cancel',
  },
};

const MESSAGE_BOX_TYPE = {
  CONFIRM: 'confirm',
  ALERT: 'alert',
};

class ConfirmationContextProvider extends Component {
  constructor(props) {
    super(props);

    this.openConfirmation = (options = defaultOptions) => {
      this.setState({ confirmationState: options });

      return new Promise((resolve, reject) => {
        this.awaitingPromise = { resolve, reject };
      });
    };

    this.closeConfirmation = () => {
      const { messageBoxType } = this.state;

      switch (messageBoxType) {
        case MESSAGE_BOX_TYPE.ALERT:
          this.awaitingPromise.resolve();
          break;

        case MESSAGE_BOX_TYPE.CONFIRM:
        default:
          this.awaitingPromise.reject();
      }

      this.setState({ confirmationState: null });
    };

    this.yes = () => {
      this.awaitingPromise.resolve();
      this.setState({ confirmationState: null });
    };

    this.no = () => {
      this.closeConfirmation();
    };

    this.confirm = (...args) => {
      this.setState({ messageBoxType: MESSAGE_BOX_TYPE.CONFIRM });

      return this.openConfirmation(...args);
    };

    this.alert = (...args) => {
      this.setState({ messageBoxType: MESSAGE_BOX_TYPE.ALERT });

      return this.openConfirmation(...args);
    };

    this.state = {
      messageBoxType: MESSAGE_BOX_TYPE.CONFIRM,
      confirmationState: null,
      confirm: this.confirm,
      alert: this.alert,
      closeConfirmation: this.closeConfirmation,
      yes: this.yes,
      no: this.no,
    };
  }

  render() {
    const { children } = this.props;
    const { messageBoxType, confirmationState } = this.state;

    const open = Boolean(confirmationState);
    const options =
      open && merge({}, defaultOptions, confirmationState);

    const yesButtonProps = {
      id: 'no',
      label: options?.buttonLabels?.no,
      onClick: this.no,
      color: 'secondary',
    };

    const noButtonProps = {
      id: 'yes',
      label: options?.buttonLabels?.yes,
      onClick: this.yes,
      color: 'primary',
    };

    const actions = [
      ...(messageBoxType === MESSAGE_BOX_TYPE.CONFIRM
        ? [yesButtonProps]
        : []),
      noButtonProps,
    ];

    return (
      <ConfirmationContext.Provider value={this.state}>
        {children}

        <Modal
          backdrop="static"
          open={open}
          onHide={this.closeConfirmation}
        >
          <Modal.Header>
            <Modal.Title>{options?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{options?.content}</Modal.Body>
          <Modal.Footer>
            {actions.map(({ id, label, onClick, color }) => (
              <Button
                id={id}
                variant="outlined"
                color={color}
                onClick={onClick}
              >
                {label}
              </Button>
            ))}
          </Modal.Footer>
        </Modal>
      </ConfirmationContext.Provider>
    );
  }
}

export default ConfirmationContextProvider;
