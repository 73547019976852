import { Children, PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';

import { round, chunk } from 'lib/javascript';

interface Props {
  cols: number;
}

const EqualColsGrid = ({
  cols,
  children,
}: PropsWithChildren<Props>) => {
  const colWidth = round(12 / cols);
  const rows = chunk(Children.toArray(children), cols);

  return (
    <>
      {rows.map((row, index) => (
        <Row key={index} className="align-center">
          {row.map((col, index) => (
            <Col key={index} xs={12} sm={6} lg={colWidth}>
              {col}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

EqualColsGrid.defaultProps = {
  cols: 3,
};

export { EqualColsGrid };
