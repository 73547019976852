// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: process.env.NEXT_PUBLIC_ENV_NAME,
  dsn:
    SENTRY_DSN ||
    'https://cdd44ba75acb499bb4c2c522842d11e2@o982188.ingest.sentry.io/5937058',
  // without this could crash 3rd party integrations such as Sumo by sending extra sentry-trace header resulting in CORS
  // https://docs.sentry.io/platforms/javascript/troubleshooting/#unexpected-options-request
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: process.env.NEXT_PUBLIC_SENTRY_TRACING_ORIGINS.split(
        ',',
      ),
    }),
  ],
  tracesSampleRate: 0.03, // The transaction quota is 10k/mnth and it’s busted in a day, so lower down the sampling rate to below: 100%/30 = 3.33% to be within the quota
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    // thrown by OneSignal web SDK over and over again after indexedDB is cleared until a new tab is opened or the browser is restarted
    // Since this error does not affect the storefront and there's nothing we could do on our end
    // ignoring this error until OneSignal solves this on their end
    // ref: https://github.com/OneSignal/OneSignal-Website-SDK/issues/503
    "Failed to execute 'transaction' on 'IDBDatabase'",
    // thrown by Microsoft Outlook SafeLink crawler
    // All of the affected users' IP belongs to Microsoft and therefore support this statement
    // ref: https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    // thrown by Stripe.js when it failed to load
    // there are 2 kind of causes:
    // 1. Microsoft Outlook SafeLink crawler (as per above)
    // 2. Being redirected to another page when Stripe.js is still loading (ref: https://github.com/stripe/stripe-js/issues/26#issuecomment-791468166)
    // either way, it's not adversely affecting real users
    'Failed to load Stripe.js',
  ],
});
