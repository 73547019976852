import qs from 'qs';

export const createQueryString = (param: QueryParamObject): string =>
  qs.stringify(param, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
export interface QueryParamObject {
  [key: string]: AllowedParam | AllowedParam[];
}

type AllowedParam = string | number | boolean | undefined | null;
