import React from 'react';

import { routePaths } from 'appConfig';
import {
  Button,
  Icon,
  Link,
  Spinner,
  Tooltip,
} from 'components/common';
import { Price } from 'lib/core/components';
import { ProductCoverImage } from 'lib/product';
import { AddonOptionValues } from 'lib/productAddon';
import { ProductLink } from 'lib/sellingProduct';
import PropTypes from 'prop-types';

import CartLineUnitPrice from '../CartLineUnitPrice';
import { CartLinePropType } from '../typedef';

const CartQuickView = ({
  loading,
  error,
  cartlist,
  total,
  isRemovingCartLine,
  removeFromCart,
  onClickViewCart,
  onClickCheckout,
}) => {
  if (loading) return <p className="text-center">Loading... </p>;

  if (error)
    return (
      <p className="text-center">
        Oh no! There was an issue loading your cart.
        <br />
        We will try again in a short while.
      </p>
    );

  if (0 === cartlist.length)
    return <p className="text-center">No products in the cart.</p>;

  return (
    <>
      <div className="dropdown-cart-products">
        {cartlist.map((item) => {
          const { id, product, quantity, error_message } = item;
          const { name, assets } = product;

          return (
            <div className="product" key={id}>
              <div className="product-cart-details">
                <h4 className="product-title">
                  <ProductLink product={product}>{name}</ProductLink>

                  {error_message && (
                    <Tooltip
                      id={`product-${id}-out-of-stock`}
                      content={error_message}
                    >
                      <Icon
                        name="exclamation-circle"
                        fontSize="1.69rem"
                        style={{
                          color: 'red',
                          verticalAlign: 'middle',
                          marginLeft: '2px',
                        }}
                      />
                    </Tooltip>
                  )}
                </h4>

                <span className="cart-product-info">
                  <span className="cart-product-qty">{quantity}</span>
                  x <CartLineUnitPrice cartLine={item} />
                </span>

                <div className="tw-inline-block tw-leading-6">
                  <AddonOptionValues
                    condensed
                    data={item.addon_option_values}
                  />
                </div>
              </div>

              {assets?.[0]?.url && (
                <figure className="product-image-container">
                  <ProductLink
                    className="product-image"
                    product={product}
                  >
                    <ProductCoverImage
                      productAssets={assets}
                      alt="product"
                      size={60}
                    />
                  </ProductLink>
                </figure>
              )}
              {isRemovingCartLine(id) ? (
                <div className="btn-remove">
                  <Spinner size="sm" />
                </div>
              ) : (
                <button
                  className="btn-remove"
                  title="Remove Product"
                  onClick={() => removeFromCart(item)}
                  disabled={isRemovingCartLine(id)}
                >
                  <i className="icon-close"></i>
                </button>
              )}
            </div>
          );
        })}
      </div>
      <div className="dropdown-cart-total">
        <span>Total</span>

        <span className="cart-total-price">
          <Price value={total} />
        </span>
      </div>

      <div className="dropdown-cart-action">
        <Link to={routePaths.CART}>
          <Button onClick={onClickViewCart}>VIEW CART</Button>
        </Link>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClickCheckout}
        >
          <span>CHECKOUT</span>
          <i className="icon-long-arrow-right"></i>
        </Button>
      </div>
    </>
  );
};

CartQuickView.propTypes = {
  loading: PropTypes.bool,
  cartlist: PropTypes.arrayOf(CartLinePropType),
  total: PropTypes.number,
  isRemovingCartLine: PropTypes.func,
  removeFromCart: PropTypes.func,
  onClickViewCart: PropTypes.func,
  onClickCheckout: PropTypes.func,
};

CartQuickView.defaultProps = {
  loading: false,
  cartlist: [],
  total: 0,
  isRemovingCartLine: () => {},
  removeFromCart: () => {},
  onClickViewCart: () => {},
  onClickCheckout: () => {},
};

export default CartQuickView;
