export const grantConsent = () => {
  window.fbq('consent', 'grant');
};

export const revokeConsent = () => {
  window.fbq('consent', 'revoke');
};

export const track = (eventName, objProps = {}) => {
  window.fbq('track', eventName, objProps);
};

export const addPaymentInfo = ({
  content_category,
  content_ids,
  contents,
  currency,
  value,
} = {}) => {
  track('AddPaymentInfo', {
    content_category,
    content_ids,
    contents,
    currency,
    value,
  });
};

export const addToCart = ({
  content_ids,
  content_name,
  content_type,
  contents,
  currency,
  value,
} = {}) => {
  track('AddToCart', {
    content_ids,
    content_name,
    content_type,
    contents,
    currency,
    value,
  });
};

export const completeRegistration = ({
  content_name,
  currency,
  status,
  value,
} = {}) => {
  track('CompleteRegistration', {
    content_name,
    currency,
    status,
    value,
  });
};

export const contact = () => {
  track('Contact');
};

export const customizeProduct = () => {
  track('CustomizeProduct');
};

export const initiateCheckout = ({
  content_category,
  content_ids,
  contents,
  currency,
  num_items,
  value,
} = {}) => {
  track('InitiateCheckout', {
    content_category,
    content_ids,
    contents,
    currency,
    num_items,
    value,
  });
};

export const lead = ({
  content_category,
  content_name,
  currency,
  value,
} = {}) => {
  track('Lead', {
    content_category,
    content_name,
    currency,
    value,
  });
};

export const pageView = () => {
  track('PageView');
};

export const purchase = ({
  content_ids,
  content_name,
  content_type,
  contents,
  currency,
  num_items,
  value,
} = {}) => {
  track('Purchase', {
    content_ids,
    content_name,
    content_type,
    contents,
    currency,
    num_items,
    value,
  });
};

export const search = ({
  content_category,
  content_ids,
  contents,
  currency,
  search_string,
  value,
} = {}) => {
  track('Search', {
    content_category,
    content_ids,
    contents,
    currency,
    search_string,
    value,
  });
};

export const viewContent = ({
  content_ids,
  content_category,
  content_name,
  content_type,
  contents,
  currency,
  value,
} = {}) => {
  track('ViewContent', {
    content_ids,
    content_category,
    content_name,
    content_type,
    contents,
    currency,
    value,
  });
};
