import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as fbq from 'lib/fb-pixel';
import { pageView } from './analytics';

const handleRouteChange = () => {
  pageView();
};

const AnalyticsPageView = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    // This pageView only trigger first time (it is important for Pixel to have real information)
    fbq.pageView();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return children;
};

export default AnalyticsPageView;
