import PropTypes from 'prop-types';
import Script from 'next/script';

/**
 * Embeds the Yotpo widget script into the dom
 */
const YotpoScripts = ({ apiKey, nonce }) => {
  return (
    <Script
      nonce={nonce}
      src={`//staticw2.yotpo.com/${apiKey}/widget.js`}
      type="text/javascript"
      strategy="lazyOnload"
    />
  );
};

YotpoScripts.propTypes = {
  apiKey: PropTypes.string.isRequired,
};

export default YotpoScripts;
