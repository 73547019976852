import useSWR from 'swr';

const useCRUD = (key, fetcher, options) => {
  const { data, error, isValidating, mutate } = useSWR(
    key,
    fetcher,
    options,
  );

  const isLoading = !data && !error;

  return {
    data,
    error,
    isValidating,
    mutate,
    isLoading,
  };
};

export default useCRUD;
