import * as api from '../api';

const GUEST_CART_ID_KEY = 'guest_cart_id';

class GuestCart {
  async getAsync() {
    try {
      const guestCartId = localStorage.getItem(GUEST_CART_ID_KEY);

      if (!guestCartId) return null;

      const guestCart = await api.getCartById(guestCartId);

      return guestCart;
    } catch (err) {
      // if something went wrong such as the guest cart doesn't exist
      // discard the guest cart ID on local storage
      // indicate that the guest cart is empty so that a new guest cart will be created in useFetchCart
      this.removeLocal();
      return null;
    }
  }

  async createAsync() {
    const createdCart = await api.createCart({ isGuest: true });

    localStorage.setItem(GUEST_CART_ID_KEY, createdCart.id);

    return createdCart;
  }

  removeLocal() {
    localStorage.removeItem(GUEST_CART_ID_KEY);
  }
}

export default GuestCart;
