import { isEmpty } from 'lib/javascript';
import { useAuthEventListener } from 'lib/auth';
import { getAppliedCoupons, hasCouponApplied } from './utils';
import * as api from './api';
import SessionCart from './SessionCart';
import { useCart } from './CartContext';

const IS_CART_MERGING_LOCAL_STORAGE_KEY = 'is_cart_merging';

const setIsCartMerging = (isCartMerging) =>
  isCartMerging
    ? localStorage.setItem(IS_CART_MERGING_LOCAL_STORAGE_KEY, 'true')
    : localStorage.removeItem(IS_CART_MERGING_LOCAL_STORAGE_KEY);

const getIsCartMerging = () =>
  localStorage.getItem(IS_CART_MERGING_LOCAL_STORAGE_KEY) === 'true';

const resolveLastResolved = async (promises) => {
  const resolvedList = [];

  await Promise.all(
    promises.map((promise) =>
      promise.then((resolved) => resolvedList.push(resolved)),
    ),
  );

  if (isEmpty(resolvedList)) throw new Error('Nothing resolved');

  const lastResolved = resolvedList[resolvedList.length - 1];

  return lastResolved;
};

const useMergeCartOnLogin = () => {
  const { refreshCart } = useCart();

  const mergeGuestCartToCart = async () => {
    try {
      if (getIsCartMerging()) return; // abort if cart is already merging (e.g. from other tab after signed in)

      setIsCartMerging(true);

      const cart = await SessionCart.current({
        isGuest: true,
      });

      const isGuestCart = !cart.user_id;

      if (!isGuestCart) return; // don't merge if current cart is not a guest cart

      const mergedCart = await api.resolveCart({
        guestCartId: cart.id,
      });

      SessionCart.use(mergedCart);
      await refreshCart();

      if (!hasCouponApplied(cart)) {
        return mergedCart;
      }

      const appliedCoupons = getAppliedCoupons(cart);

      const latestCartWithCouponApplied = await resolveLastResolved(
        appliedCoupons.map((appliedCoupon) =>
          api.applyCouponToCart(
            mergedCart.id,
            appliedCoupon.coupon_code,
          ),
        ),
      );

      await refreshCart();

      return latestCartWithCouponApplied;
    } finally {
      setIsCartMerging(false);
    }
  };

  useAuthEventListener({
    onSignInSuccess: mergeGuestCartToCart,
  });
};

export default useMergeCartOnLogin;
