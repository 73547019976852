import React from 'react';
import { useRouter } from 'next/router';
import { useAuth, User } from 'lib/auth';
import { useUser } from 'lib/account';
import { routePaths } from 'appConfig';

import { displayName } from 'lib/utils';

const UserMenu = () => {
  const { session } = useAuth();
  const { user } = useUser(session?.user?.userId);
  const router = useRouter();

  const fullName = displayName({
    firstName: user?.first_name,
    lastName: user?.last_name,
  });

  const redirectUser = () => {
    session
      ? router.push(routePaths.ACCOUNT)
      : router.push(routePaths.LOGIN);
  };

  return (
    <div className="cart-dropdown">
      <div
        className="wishlist-link"
        style={{ cursor: 'pointer' }}
        onClick={redirectUser}
      >
        <i className="icon-user"></i>
        <span>{fullName}</span>
      </div>

      <div
        className="dropdown-menu dropdown-menu-right"
        style={{ width: 'auto' }}
      >
        <User />
      </div>
    </div>
  );
};

export default UserMenu;
