import Link from 'components/common/link';
import { routePaths } from 'appConfig';
import { aboutUsItems, cateringItems, mealPreps } from './utils';

export default function MainMenu(props) {
  return (
    <nav className="main-nav">
      <ul className="menu sf-arrows">
        <li className={`megamenu-container`} id="menu-home">
          <Link exact to={'/'} activeClassName="active">
            <>Home</>
          </Link>
        </li>

        <li>
          <a className="sf-with-ul" style={{ cursor: 'pointer' }}>
            About Us
          </a>

          <ul>
            {aboutUsItems.map((item) => (
              <li key={item.to}>
                <Link to={item.to} activeClassName="active">
                  <>{item.label}</>
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <a className="sf-with-ul" style={{ cursor: 'pointer' }}>
            Meal Prep
          </a>

          <ul>
            {mealPreps.map((item) => (
              <li>
                <Link to={item.to} activeClassName="active">
                  <>{item.label}</>
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <a className="sf-with-ul" style={{ cursor: 'pointer' }}>
            Catering
          </a>

          <ul>
            {cateringItems.map((item) => (
              <li>
                <Link to={item.to} activeClassName="active">
                  <>{item.label}</>
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <Link to={routePaths.CATEGORIES} activeClassName="active">
            <>Order Now</>
          </Link>
        </li>

        <li>
          <Link to={routePaths.YB_EXPRESS} target="_blank">
            <>YB Express</>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
