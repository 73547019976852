export const DELIVERY_TIMESLOT_DURATION_HOURS = 3;
export const DELIVERY_SLOT_CONDITION = {
  BLOCKED: 'blocked',
  MOVED: 'moved',
};

export const OPERATION_DATETIME_TYPE = {
  DELIVERY: 'delivery',
  CUTOFF: 'cut-off',
};
