/**
 * @param {Object} params The parameters object.
 * @param {string} params.firstName The first value
 * @param {string} params.lastName The second value
 * @param {string=} params.email The second value
 * @returns {string}
 */
const displayName = ({ firstName, lastName, email }) => {
  const fullName = [firstName, lastName] // american name order
    .filter((name) => name) // filter off null or undefined values
    .join(' ');

  if (!fullName) return email;

  return fullName;
};

/**
 * @param {User} user
 * @returns {string}
 */
const getFirstName = ({ firstName }) => {
  return firstName;
};

export default displayName;
export { getFirstName };
