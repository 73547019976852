import { useEffect } from 'react';
import { useAuthStatus } from './AuthStatusContext';
import { AUTH_STATUS } from './constants';

const useAuthEventListener = ({
  onSignInSuccess = () => {},
  onSignOutSuccess = () => {},
}) => {
  const { authStatus } = useAuthStatus();

  useEffect(() => {
    switch (authStatus) {
      case AUTH_STATUS.SIGN_IN_SUCCESS:
        onSignInSuccess();
        break;
      case AUTH_STATUS.SIGN_OUT_SUCCESS:
        onSignOutSuccess();
        break;
      default:
    }
  }, [authStatus, onSignInSuccess, onSignOutSuccess]);
};

export default useAuthEventListener;
