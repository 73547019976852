import { useEffect } from 'react';
import useAuth from './useAuth';
import { LoggedInTabTracker } from './services';

const useForgetLoggedInTabOnSignOut = () => {
  const { session } = useAuth();

  useEffect(() => {
    if (!session) LoggedInTabTracker.isLoggedInFromCurrentTab = false;
  }, [session]);
};

export default useForgetLoggedInTabOnSignOut;
