import React from 'react';

export const cartContextDefaultValues = {
  // update cart line
  updatingCartLineIds: [],
  addUpdatingCartLineId: () => {},
  removeUpdatingCartLineId: () => {},
  isRemovingCartLine: () => {},

  // remove cart line
  removingCartLineIds: [],
  addRemovingCartLineId: () => {},
  removeRemovingCartLineId: () => {},
  isUpdatingCartLine: () => {},
};

const CartContext = React.createContext(cartContextDefaultValues);

export default CartContext;
