import React from 'react';

import CartContext from './CartContext';
import useFetchCart from './useFetchCart';

const useCart = () => {
  const { isRemovingCartLine, isUpdatingCartLine } = React.useContext(
    CartContext,
  );

  const {
    isLoading: isFetchingCart,
    cart,
    setCart,
    error,
    refreshCart,
    removeCartLine,
    addToCart,
    updateCartLineQuantity,
    updateCartLineVariant,
    applyCouponToCart,
    removeCouponFromCart,
    applyLoyaltyPointsToCart,
    removeLoyaltyPointsFromCart,
    popCartInUse,
    getCartSource,
  } = useFetchCart();

  return {
    loading: isFetchingCart,
    cart,
    setCart,
    error,
    refreshCart,
    removeCartLine,
    addToCart,
    updateCartLineQuantity,
    updateCartLineVariant,
    isRemovingCartLine,
    isUpdatingCartLine,
    applyCouponToCart,
    removeCouponFromCart,
    applyLoyaltyPointsToCart,
    removeLoyaltyPointsFromCart,
    popCartInUse,
    getCartSource,
  };
};

export default useCart;
