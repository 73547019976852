import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import toObject from 'dayjs/plugin/toObject';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const utcFix = (_option, dayjsClass) => {
  dayjsClass.prototype.valueOf = function () {
    const addedOffset = !this.$utils().u(this.$offset)
      ? this.$offset +
        (this.$x.$localOffset || this.$d.getTimezoneOffset())
      : 0;
    return this.$d.valueOf() - addedOffset * 60 * 1000;
  };
};

dayjs.extend(relativeTime);
dayjs.extend(toObject);
dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
// to patch issue in timezone with DST
// must be registered after the original utc plugin in order to patch it
// Once this PR is published: https://github.com/iamkun/dayjs/pull/1448
// might no longer need this patch
dayjs.extend(utcFix);
dayjs.extend(timezone);

export default dayjs;
