export const PAYMENT_METHOD = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  PAYPAL: 'paypal',
  AMEX: 'amex',
};

export const CARD_PAYMENT_METHOD_LIST = [
  PAYMENT_METHOD.VISA,
  PAYMENT_METHOD.MASTERCARD,
  PAYMENT_METHOD.AMEX,
];

export const isPaymentMethodCard = (paymentMethod) =>
  CARD_PAYMENT_METHOD_LIST.includes(paymentMethod);

export const PAYMENT_STATUS = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  PROCESSING: 'processing',
  REQUIRES_ACTION: 'requires_action',
  REQUIRES_SOURCE_ACTION: 'requires_source_action',
};

export const PAYNOW_ERROR_CODE = {
  PAYMENT_ATTEMPT_EXPIRED: 'payment_intent_payment_attempt_expired',
};

export const CARD_PAYMENT_GATEWAY_ERROR_TYPE = {
  API_CONNECTION_ERROR: 'api_connection_error',
  API_ERROR: 'api_error',
  AUTHENTICATION_ERROR: 'authentication_error',
  CARD_ERROR: 'card_error',
  IDEMPOTENCY_ERROR: 'idempotency_error',
  INVALID_REQUEST_ERROR: 'invalid_request_error',
  RATE_LIMIT_ERROR: 'rate_limit_error',
};

export const CARD_PAYMENT_GATEWAY_ERROR_CODE = {
  CARD_DECLINE_RATE_LIMIT_EXCEEDED:
    'card_decline_rate_limit_exceeded',
};

export const getCardPaymentGatewayErrorMessage = (error) => {
  switch (error.type) {
    case CARD_PAYMENT_GATEWAY_ERROR_TYPE.CARD_ERROR:
      if (
        error.code ===
        CARD_PAYMENT_GATEWAY_ERROR_CODE.CARD_DECLINE_RATE_LIMIT_EXCEEDED
      )
        return 'You have exceeded the maximum number of declines on this card in the last 24 hour period. Please try again the next day. ';

      return error.message;

    case CARD_PAYMENT_GATEWAY_ERROR_TYPE.INVALID_REQUEST_ERROR:
      return error.message;

    default:
      return "It's not you, but us! We are sorry but please try again. If this issue persists, please contact us. ";
  }
};

export const isCardPaymentGatewayError = (error) => {
  return (
    Object.values(CARD_PAYMENT_GATEWAY_ERROR_TYPE).includes(
      error?.type,
    ) &&
    error?.code &&
    error?.message
  );
};

export const hasCardPaymentError = (cardPaymentResponse) => {
  return Boolean(cardPaymentResponse.error);
};

export const isCardPaymentProcessing = (cardPaymentResponse) => {
  return cardPaymentResponse.paymentIntent.status === 'processing';
};

export const makeCardLabel = (card) => {
  return `${card.brand} ending with ${card.last4}`;
};

export const isPayNowPaymentExpired = (paymentIntent) => {
  return (
    paymentIntent?.last_payment_error?.code ===
    PAYNOW_ERROR_CODE.PAYMENT_ATTEMPT_EXPIRED
  );
};
