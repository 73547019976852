import React from 'react';
import Link from 'components/common/link';
import classNames from 'classnames';

import MobileMainNav from './partials/mobile-nav';
import { routePaths } from 'appConfig';
// import { useSearch } from 'lib/search';
import { useAuth } from 'lib/auth';

import { mobileMenu } from 'utils';

export default function MobileMenu(props) {
  const { session } = useAuth();
  const { adClass } = props;
  // const { searchTerm, setSearchTerm, showFullResults } = useSearch();

  React.useEffect(() => {
    mobileMenu();
  }, [session]); // re-attach click event handlers (which dimisses the mobile menu) after user is logged in or logged out
  // this is needed because some links (e.g. Sign Out / Register) are dynamically rendered on session change,
  // which means links that are added later would not have the click event handlers attached

  return (
    <React.Fragment>
      <div className="mobile-menu-overlay" />
      <div className={classNames('mobile-menu-container', adClass)}>
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close"></i>
          </span>

          {/* <form
            className="mobile-search"
            onSubmit={(e) => {
              e.preventDefault();
              showFullResults();
            }}
          >
            <label htmlFor="mobile-search" className="sr-only">
              Search
            </label>
            <input
              type="search"
              className="form-control"
              name="mobile-search"
              id="mobile-search"
              placeholder="Search in..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              // required
            />
            <button className="btn btn-primary" type="submit">
              <i className="icon-search"></i>
            </button>
          </form> */}

          <nav className="mobile-nav">
            <MobileMainNav />
          </nav>

          <div className="social-icons">
            <Link
              to={routePaths.FACEBOOK}
              className="social-icon"
              target="_blank"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </Link>
            <Link
              to={routePaths.INSTAGRAM}
              className="social-icon"
              target="_blank"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </Link>
            <Link
              to={routePaths.YOUTUBE}
              className="social-icon"
              target="_blank"
              title="Youtube"
            >
              <i className="icon-youtube"></i>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
