import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { MAILCHIMP_CAMPAIGN_ID_QUERY_PARAM } from '../constants';
import { setMailchimpCampaignIdToCookie } from '../utils';

const useSaveMailchimpCampaignId = () => {
  const { query } = useRouter();

  const campaignId = query[MAILCHIMP_CAMPAIGN_ID_QUERY_PARAM];

  useEffect(() => {
    if (campaignId) setMailchimpCampaignIdToCookie(campaignId);
  }, [campaignId]);
};

export default useSaveMailchimpCampaignId;
