import { createContext, Component, useContext } from 'react';
import PropTypes from 'prop-types';

const discountContextDefaultValues = {
  method: null,
  setMethod: (method) => {},
};

const DiscountContext = createContext(discountContextDefaultValues);

class DiscountContextProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.setMethod = (method) => {
      this.setState({ method });
    };

    this.state = {
      method: discountContextDefaultValues.method,
      setMethod: this.setMethod,
    };
  }

  render() {
    const { children } = this.props;

    return (
      <DiscountContext.Provider value={this.state}>
        {children}
      </DiscountContext.Provider>
    );
  }
}

const DiscountContextConsumer = DiscountContext.Consumer;

const useDiscountContext = () => {
  const discountContext = useContext(DiscountContext);

  return discountContext;
};

export {
  DiscountContextProvider,
  DiscountContextConsumer,
  useDiscountContext,
};

export default DiscountContext;
