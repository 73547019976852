import axios from 'axios';
import qs from 'qs';
import config from 'appConfig';
import { isPlainObject, isString } from 'lib/javascript';

import {
  getHttpClientLogger,
  getUserFromHttpClientConfig,
} from './logger';

const formatData = (requestOrResponseData) => {
  if (isPlainObject(requestOrResponseData))
    return JSON.stringify(requestOrResponseData);

  if (isString(requestOrResponseData)) return requestOrResponseData;

  return undefined;
};

const requestConfigToLogMetadata = (config) => {
  const { baseURL, headers, data } = config;

  return {
    user: getUserFromHttpClientConfig(config),
    request: {
      baseURL,
      headers,
      data: formatData(data),
    },
  };
};

const responseToLogMetadata = (response) => {
  const { config, data, status } = response;

  return {
    ...requestConfigToLogMetadata(config),
    response: {
      status,
      data: formatData(data),
    },
  };
};

const init = () => {
  const instance = axios.create({
    baseURL: config.api.baseURL,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'comma' }),
  });

  instance.interceptors.request.use(async (config) => {
    const { method, url } = config;

    const logger = await getHttpClientLogger();

    logger.info(
      requestConfigToLogMetadata(config),
      `START http request ${method} ${url}`,
    );

    return config;
  });

  instance.interceptors.response.use(
    async (response) => {
      const { config } = response;
      const { method, url } = config;

      const logger = await getHttpClientLogger();

      logger.info(
        responseToLogMetadata(response),
        `SUCCESS http request ${method} ${url}`,
      );

      return response;
    },
    async (error) => {
      const logger = await getHttpClientLogger();

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { method, url } = error.response.config;

        logger.error(
          {
            error: JSON.stringify(error),
            user: getUserFromHttpClientConfig(error.response.config),
          },
          `FAILED http response ${method} ${url}`,
        );
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        logger.error(
          { error: JSON.stringify(error.request) },
          'FAILED http response but unable to identify which one. This request was made but no response was received. ',
        );
      } else {
        // Something happened in setting up the request that triggered an error
        // (not request error, but most likely programmatically error)
        logger.error(
          { error: error.message },
          'FAILED http response but unable to identify which one. This is most likely not a request error but a programmatical error. ',
        );
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export default init;
