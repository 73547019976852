import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useCallback, useMemo } from 'react';
import {
  getCardPaymentGatewayErrorMessage,
  hasCardPaymentError,
  isCardPaymentProcessing,
  isPayNowPaymentExpired,
  PAYMENT_STATUS,
} from './utils';
import {
  getPaymentIntent as fetchPaymentIntent,
  getPaymentIntentByOrderId,
} from './api';
import {
  ConfirmCardPaymentData,
  ConfirmCardSetupData,
} from '@stripe/stripe-js';
import { getPaymentLogger } from './logger';
import { useAuth } from 'lib/auth';
import appConfig, { routePaths } from 'appConfig';
import { PaymentMethod } from './types';

const usePayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { session } = useAuth();
  const user = session?.user;

  const isLoading = useMemo(() => !stripe || !elements, [
    stripe,
    elements,
  ]);

  const getPaymentIntent = async (
    orderId: string,
    paymentMethod: PaymentMethod,
  ): Promise<{ client_secret: string }> => {
    const paymentIntent = await fetchPaymentIntent(
      orderId,
      paymentMethod,
    );

    return paymentIntent.data;
  };

  const retrievePaymentIntent = async (
    orderId: string,
  ): Promise<{
    client_secret: string;
    payment_method: string;
    used_payment_method: PaymentMethod;
  }> => {
    const paymentIntent = await getPaymentIntentByOrderId(orderId);

    return paymentIntent.data;
  };

  const getCardInput = useCallback(() => {
    if (!elements) return;

    return elements.getElement(CardElement);
  }, [elements]);

  const confirmCardSetup = useCallback(
    async (
      clientSecret: string,
      { payment_method }: ConfirmCardSetupData,
    ) => {
      const logger = await getPaymentLogger();

      try {
        logger.info({ user }, 'START stripe.confirmCardSetup');

        const confirmCardSetupResult = await stripe?.confirmCardSetup(
          clientSecret,
          {
            payment_method,
          },
        );

        logger.info(
          {
            user,
            response: {
              data: JSON.stringify(confirmCardSetupResult),
            },
          },
          'SUCCESS stripe.confirmCardSetup',
        );

        return confirmCardSetupResult;
      } catch (err) {
        logger.error(
          { user, error: err.message },
          'FAILED stripe.confirmCardSetup',
        );

        throw err;
      }
    },
    [stripe, user],
  );

  const confirmCardPayment = useCallback(
    async (
      clientSecret: string,
      { payment_method }: ConfirmCardPaymentData,
    ) => {
      const logger = await getPaymentLogger();

      try {
        logger.info(
          {
            user,
            request: { data: JSON.stringify({ payment_method }) },
          },
          'START stripe.confirmCardPayment',
        );

        const cardPaymentResponse = await stripe?.confirmCardPayment(
          clientSecret,
          {
            payment_method,
          },
        );

        if (hasCardPaymentError(cardPaymentResponse)) {
          throw new Error(
            getCardPaymentGatewayErrorMessage(
              cardPaymentResponse?.error,
            ),
          );
        }

        logger.info(
          {
            user,
            response: { data: JSON.stringify(cardPaymentResponse) },
          },
          'SUCCESS stripe.confirmCardPayment',
        );

        if (isCardPaymentProcessing(cardPaymentResponse))
          return {
            status: PAYMENT_STATUS.PROCESSING,
          };

        return {
          status: PAYMENT_STATUS.SUCCEEDED,
        };
      } catch (err) {
        logger.error(
          {
            user,
            error: err.message,
          },
          'FAILED stripe.confirmCardPayment',
        );

        return {
          status: PAYMENT_STATUS.FAILED,
          message: err.message,
        };
      }
    },
    [stripe, user],
  );

  const confirmPayNowPayment = useCallback(
    async (
      clientSecret: string,
    ): Promise<{ status: string; message?: string }> => {
      const logger = await getPaymentLogger();

      try {
        logger.info({ user }, 'START stripe.confirmPayNowPayment');

        const response = await stripe?.confirmPayNowPayment(
          clientSecret,
        );

        if (isPayNowPaymentExpired(response?.paymentIntent)) {
          return {
            status: PAYMENT_STATUS.FAILED,
            message: 'Your payment attempt timed out.',
          };
        }

        if (
          response?.paymentIntent?.status ===
          PAYMENT_STATUS.REQUIRES_ACTION
        ) {
          return {
            status: PAYMENT_STATUS.REQUIRES_ACTION,
          };
        }

        if (
          response?.paymentIntent?.status ===
          PAYMENT_STATUS.REQUIRES_SOURCE_ACTION
        ) {
          return { status: PAYMENT_STATUS.REQUIRES_SOURCE_ACTION };
        }

        logger.info(
          {
            user,
            response: {
              data: JSON.stringify(response),
            },
          },
          'SUCCESS stripe.confirmPayNowPayment',
        );

        return { status: PAYMENT_STATUS.SUCCEEDED };
      } catch (err) {
        logger.error(
          { user, error: err.message },
          'FAILED stripe.confirmPayNowPayment',
        );

        return {
          status: PAYMENT_STATUS.FAILED,
          message: err.message,
        };
      }
    },
    [stripe, user],
  );

  const confirmGrabPayPayment = useCallback(
    async (clientSecret: string, orderId: string) => {
      await stripe?.confirmGrabPayPayment(clientSecret, {
        return_url: `${appConfig.appBaseURL}${routePaths.PAYMENT_CALLBACK}?orderId=${orderId}&method=grabpay`,
      });
    },
    [stripe],
  );

  return {
    isLoading,
    getCardInput,
    confirmCardSetup,
    confirmPayNowPayment,
    confirmGrabPayPayment,
    getPaymentIntent,
    confirmCardPayment,
    retrievePaymentIntent,
  };
};

export default usePayment;
