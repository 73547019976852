import { useAuthEventListener } from 'lib/auth';
import { ACTIVE_ADDRESS_SESSION_KEY } from './constants';

const useClearActiveAddressOnLogout = () => {
  useAuthEventListener({
    onSignOutSuccess: () => {
      window.sessionStorage.removeItem(ACTIVE_ADDRESS_SESSION_KEY);
    },
  });
};

export default useClearActiveAddressOnLogout;
