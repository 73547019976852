export const MIN_DONATION_AMOUNT = 2;
export const MAX_DONATION_AMOUNT = 10;

export const DONATION_TYPE = {
  WITHOUT: 'without',
  FIXED: 'fixed',
  CHOICE: 'choice',
};

export const LABEL_BY_DONATION_TYPE = {
  [DONATION_TYPE.WITHOUT]: 'Do not donate',
  [DONATION_TYPE.FIXED]: 'Donate $2.00',
  [DONATION_TYPE.CHOICE]:
    'Donate an amount of my choice (Min. $2, Max. $10)',
};
