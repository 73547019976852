export const STEPS = {
  AUTH: 'auth',
  DELIVERY: 'delivery',
};

export const LABEL_BY_STEP = {
  [STEPS.AUTH]: '1. Sign In/Register',
  [STEPS.DELIVERY]: '2. Select Delivery & Billing',
};

export const STEP_ORDERS = [STEPS.AUTH, STEPS.DELIVERY];

export const DELIVERY_METHODS = {
  DOORSTEP: 0,
  CNC: 1,
};

export const LABEL_BY_DELIVERY_METHOD = {
  [DELIVERY_METHODS.DOORSTEP]: 'Doorstep Delivery',
  [DELIVERY_METHODS.CNC]: 'Click & Collect',
};

export const VALIDATION_TYPE = {
  SESSION: 'session',
  DELIVERY_DATE_TIME: 'delivery_date_time',
  ADDRESSES: 'addresses',
  PAYMENT_METHOD: 'payment_method',
  TERMS_AND_CONDITION: 'terms_and_condition',
  COUPON: 'coupon',
  PAYMENT_AMOUNT: 'payment_amount',
};

export const REQUIRED_CHECKOUT_VALIDATION_TYPES = [
  VALIDATION_TYPE.SESSION,
  VALIDATION_TYPE.DELIVERY_DATE_TIME,
  VALIDATION_TYPE.ADDRESSES,
  VALIDATION_TYPE.PAYMENT_METHOD,
  VALIDATION_TYPE.TERMS_AND_CONDITION,
  VALIDATION_TYPE.COUPON,
  VALIDATION_TYPE.PAYMENT_AMOUNT,
];
