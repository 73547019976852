import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useUserAddresses from './useUserAddresses';
import {
  Address as AddressInterface,
  AddressPayload,
  GetUserAddressResponse,
} from './types';
import { getAddressesByType } from './utils';
import useSessionStorage from 'hooks/useSessionStorage';
import { ACTIVE_ADDRESS_SESSION_KEY } from './constants';

interface AddressContextProps {
  isLoading: boolean;
  shippingAddresses: AddressInterface[];
  billingAddresses: AddressInterface[];
  orderAddresses: AddressInterface[];
  activeAddress: AddressInterface | null;
  setActiveAddress: (address: AddressInterface) => void;
  createAddress: (
    payload: AddressPayload,
  ) => Promise<GetUserAddressResponse | undefined>;
  markAddressAsDefault: (
    addressId: string,
  ) => Promise<GetUserAddressResponse | undefined>;
  updateAddress: (
    addressId: string,
    payload: AddressPayload,
  ) => Promise<GetUserAddressResponse | undefined>;
  deleteAddress: (addressId: string) => Promise<void>;
}

export const addressContextDefaultValues: AddressContextProps = {
  isLoading: false,
  shippingAddresses: [],
  billingAddresses: [],
  orderAddresses: [],
  activeAddress: null,
  setActiveAddress: () => {},
  createAddress: async () => undefined,
  markAddressAsDefault: async (addressId: string) => undefined,
  updateAddress: async (addressId: string, payload: AddressPayload) =>
    undefined,
  deleteAddress: async (addressId: string) => undefined,
};

export const AddressContext = createContext<AddressContextProps>(
  addressContextDefaultValues,
);

export const AddressContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    addresses,
    createAddress,
    markAddressAsDefault,
    updateAddress,
    deleteAddress,
    isLoading,
  } = useUserAddresses();

  const shippingAddresses = useMemo(
    () => getAddressesByType('shipping', addresses),
    [addresses],
  );

  const billingAddresses = useMemo(
    () => getAddressesByType('billing', addresses),
    [addresses],
  );

  const [
    localAddress,
    setSessionStorage,
  ] = useSessionStorage<AddressInterface | null>(
    ACTIVE_ADDRESS_SESSION_KEY,
    null,
  );

  const [orderAddresses, setOrderAddresses] = useState<
    Array<AddressInterface>
  >([]);

  useEffect(() => {
    // Set default activeAddress if not set
    if (localAddress === null && shippingAddresses.length) {
      const defaultShippingAddress = shippingAddresses.find(
        (addr) => addr.default,
      );
      const defaultAddress =
        defaultShippingAddress || shippingAddresses[0];

      setSessionStorage(defaultAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAddress, shippingAddresses]);

  useEffect(() => {
    // Update order addresses when active address or billing addresses change
    if (localAddress && billingAddresses.length) {
      setOrderAddresses([localAddress, billingAddresses[0]]);
    }
  }, [localAddress, billingAddresses]);

  useEffect(() => {
    const activeAddress = shippingAddresses.find(
      (addr) => addr.id === localAddress?.id,
    );

    if (activeAddress) {
      setSessionStorage(activeAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddresses]);

  // Memoized setActiveAddress function
  const setActiveAddress = useCallback(
    (address: AddressInterface) => {
      setSessionStorage(address);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const _deleteAddress = useCallback(
    async (addressId: string) => {
      await deleteAddress(addressId);
      if (localAddress?.id === addressId) {
        setSessionStorage(null);
      }
    },
    [deleteAddress, localAddress?.id, setSessionStorage],
  );

  const contextValue = useMemo(
    () => ({
      isLoading,
      shippingAddresses,
      billingAddresses,
      activeAddress: localAddress,
      orderAddresses,
      createAddress,
      setActiveAddress,
      markAddressAsDefault,
      updateAddress,
      deleteAddress: _deleteAddress,
    }),
    [
      isLoading,
      shippingAddresses,
      billingAddresses,
      localAddress,
      orderAddresses,
      createAddress,
      setActiveAddress,
      markAddressAsDefault,
      updateAddress,
      _deleteAddress,
    ],
  );

  return (
    // @ts-ignore
    <AddressContext.Provider value={contextValue}>
      {children}
    </AddressContext.Provider>
  );
};

export const useAddressContext = () => useContext(AddressContext);
