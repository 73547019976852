import React from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lib/javascript';

import { ApplyCouponCodeFormContextProvider } from 'lib/coupons';
import { DiscountContextProvider } from 'lib/discount';

import CartContext, { cartContextDefaultValues } from './CartContext';

class CartContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.addUpdatingCartLineId = (cartLineId) =>
      this.setState((state) => ({
        updatingCartLineIds: uniq([
          ...state.updatingCartLineIds,
          cartLineId,
        ]),
      }));

    this.removeUpdatingCartLineId = (cartLineId) =>
      this.setState((state) => ({
        updatingCartLineIds: state.updatingCartLineIds.filter(
          (updatingCartLineId) => updatingCartLineId !== cartLineId,
        ),
      }));

    this.isUpdatingCartLine = (cartLineId) => {
      const { updatingCartLineIds } = this.state;

      return updatingCartLineIds.includes(cartLineId);
    };

    this.addRemovingCartLineId = (cartLineId) =>
      this.setState((state) => ({
        removingCartLineIds: uniq([
          ...state.removingCartLineIds,
          cartLineId,
        ]),
      }));

    this.removeRemovingCartLineId = (cartLineId) =>
      this.setState((state) => ({
        removingCartLineIds: state.removingCartLineIds.filter(
          (removingCartLineId) => removingCartLineId !== cartLineId,
        ),
      }));

    this.isRemovingCartLine = (cartLineId) => {
      const { removingCartLineIds } = this.state;

      return removingCartLineIds.includes(cartLineId);
    };

    this.state = {
      updatingCartLineIds:
        cartContextDefaultValues.updatingCartLineIds,
      addUpdatingCartLineId: this.addUpdatingCartLineId,
      removeUpdatingCartLineId: this.removeUpdatingCartLineId,
      isUpdatingCartLine: this.isUpdatingCartLine,

      removingCartLineIds:
        cartContextDefaultValues.removingCartLineIds,
      addRemovingCartLineId: this.addRemovingCartLineId,
      removeRemovingCartLineId: this.removeRemovingCartLineId,
      isRemovingCartLine: this.isRemovingCartLine,
    };
  }

  render() {
    const { children } = this.props;

    return (
      <CartContext.Provider value={this.state}>
        {children}
      </CartContext.Provider>
    );
  }
}

CartContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const CartContextProviderContainer = ({ children }) => (
  <ApplyCouponCodeFormContextProvider>
    <DiscountContextProvider>
      <CartContextProvider>{children}</CartContextProvider>
    </DiscountContextProvider>
  </ApplyCouponCodeFormContextProvider>
);

export default CartContextProviderContainer;
