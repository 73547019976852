import { findIndex, isBrowser } from 'utils';

import { isCoverImage } from 'lib/media';
import { getProductPath } from 'lib/router';
import * as productVariants from 'lib/productVariants';
import { makeDataCacheKey } from 'lib/swr-crud';

import { PRODUCT_FAMILY } from './constants';

export const makeProductsCacheKey = (queryParams) => {
  return makeDataCacheKey('/products', queryParams);
};

export const makeProductCacheKey = (productId) => {
  return makeDataCacheKey(`/products/${productId}`);
};

/**
 * Get visible products
 *
 * @param {Array} products
 * @param {Object} param1
 * @return {Array} filtered products
 */
export const getVisibleProducts = (
  products,
  { sortBy, category, size, brand, color, rating, value },
) => {
  return products
    .filter((item) => {
      let catResult = false,
        sizeResult = false,
        brandResult = false,
        colorResult = false,
        valResult = false,
        ratingResult = false;

      if (category && category.length > 0) {
        for (let i = 0; i < category.length; i++) {
          if (
            -1 !==
              findIndex(
                item.category,
                (cat) => cat === category[i],
              ) ||
            (category[i] === 'Sale' && item.discount > 0) ||
            category[i] === 'All'
          )
            catResult = true;
        }
      } else {
        catResult = true;
      }

      if (size && size.length > 0) {
        for (let i = 0; i < size.length; i++) {
          if (-1 !== findIndex(item.size, (sz) => sz === size[i]))
            sizeResult = true;
        }
      } else {
        sizeResult = true;
      }

      if (brand && brand.length > 0) {
        for (let i = 0; i < brand.length; i++) {
          if (-1 !== findIndex(item.brands, (br) => br === brand[i]))
            brandResult = true;
        }
      } else {
        brandResult = true;
      }

      if (color && color.length > 0) {
        for (let i = 0; i < color.length; i++) {
          if (
            -1 !==
            findIndex(item.variants, (cl) => cl.color === color[i])
          )
            colorResult = true;
        }
      } else {
        colorResult = true;
      }

      if (rating && rating.length > 0) {
        for (let i = 0; i < rating.length; i++) {
          if (item.ratings === rating[i]) ratingResult = true;
        }
      } else {
        ratingResult = true;
      }

      let price = item.discount
        ? item.salePrice
        : item.variants?.[0].price;

      if (value && value.min <= price && price <= value.max) {
        valResult = true;
      }

      return (
        catResult &&
        sizeResult &&
        brandResult &&
        colorResult &&
        valResult &&
        ratingResult
      );
    })
    .sort((product1, product2) => {
      switch (sortBy) {
        case 'date':
          const latestFirst =
            product1.publish_at - product2.publish_at;
          return latestFirst;

        default:
          const byId = product2.id < product1.id ? -1 : 1;
          return byId;
      }
    });
};

export const findAvailableProductVariant = (product) => {
  return product?.variants.find((variant) => variant.stock > 0);
};

export const getDisplayingProductVariant = (product) => {
  return (
    findAvailableProductVariant(product) || product?.variants?.[0]
  );
};

export const findFirstCoverImageUrl = (product) => {
  return product?.assets?.filter(isCoverImage)?.[0]?.url;
};

export const makeProductUrl = (product) => {
  if (!isBrowser()) return null;

  return `${window.location.origin}/${getProductPath(product.slug)}`;
};

export const getTotalStock = (product) => {
  if (!product) return 0;

  if (productVariants.utils.hasProductVariants(product))
    return product.variants.reduce((result, variant) => {
      result += variant.stock;

      return result;
    }, 0);

  return product?.variants?.[0].stock;
};

export const isOutOfStock = (product) => {
  return getTotalStock(product) <= 0;
};

export const isCustomizableMeal = (product) => {
  return product?.product_family === PRODUCT_FAMILY.CUSTOMIZABLE_MEAL;
};
