import React, { CSSProperties, ReactNode } from 'react';

import { safeContent } from 'utils';

export enum IconBoxTheme {
  LIGHT = 'light',
  DARK = 'dark',
}
export type IconBoxThemeString = `${IconBoxTheme}`;

export enum IconBoxStyle {
  CENTER = 'text-center',
  LEFT = 'icon-box-left',
  CIRCLE_CENTER = 'icon-box-circle text-center',
  CIRCLE_LEFT = 'icon-box-circle icon-box-left',
}
export type IconBoxStyleString = `${IconBoxStyle}`;

interface Props {
  boxStyle: IconBoxStyleString | IconBoxStyle;
  icon: ReactNode;
  theme: IconBoxTheme | IconBoxThemeString;
  title?: ReactNode;
  text: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const IconBox = ({
  boxStyle,
  icon,
  title,
  text,
  theme,
  style = {},
  className,
}: Props) => {
  const paragraph =
    typeof text === 'string' ? (
      <p dangerouslySetInnerHTML={safeContent(text)}></p>
    ) : (
      <p>{text}</p>
    );

  return (
    <div
      className={`icon-box ${boxStyle} ${className}`}
      style={style}
    >
      <span className={`icon-box-icon`}>{icon}</span>

      <div className="icon-box-content">
        <h3
          className="icon-box-title"
          style={theme === IconBoxTheme.DARK ? { color: '#FFF' } : {}}
        >
          {title}
        </h3>
        {paragraph}
      </div>
    </div>
  );
};

IconBox.defaultProps = {
  theme: IconBoxTheme.LIGHT,
};

export default React.memo(IconBox);
