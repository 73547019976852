import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LoveMessage, LoveMessagesQueryParams } from './types';
import useUserLoveMessages from './use-user-love-messages';
import { useRouter } from 'next/router';

interface LoveMessageContextProps {
  isLoading: boolean;
  loveMessages: LoveMessage[];
  unreadLoveMessage: LoveMessage | null;
  params?: LoveMessagesQueryParams;
  setQueryParams: Dispatch<SetStateAction<LoveMessagesQueryParams>>;
  dismissLoveMessage: (loveMessageId: string) => Promise<void>;
  total: number;
}

const defaultValues: LoveMessageContextProps = {
  isLoading: false,
  loveMessages: [],
  unreadLoveMessage: null,
  params: undefined,
  setQueryParams: () => {},
  dismissLoveMessage: async (_: string) => undefined,
  total: 0,
};

export const LoveMessageContext = createContext<LoveMessageContextProps>(
  defaultValues,
);

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 15;

export const LoveMessageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [params, setParams] = useState<LoveMessagesQueryParams>({
    page: DEFAULT_PAGE,
    perPage: DEFAULT_PER_PAGE,
  });
  const { asPath, query } = useRouter();

  const {
    loveMessages,
    unreadLoveMessage,
    dismissLoveMessage,
    isLoading,
    total,
  } = useUserLoveMessages(params);

  useEffect(() => {
    if (
      asPath !== '/account?tab=loveMessage' &&
      (params.page !== DEFAULT_PAGE ||
        params.perPage !== DEFAULT_PER_PAGE)
    ) {
      setParams({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, query]);

  return (
    <LoveMessageContext.Provider
      value={{
        isLoading,
        loveMessages,
        unreadLoveMessage,
        params,
        setQueryParams: setParams,
        dismissLoveMessage,
        total,
      }}
    >
      {children}
    </LoveMessageContext.Provider>
  );
};

export const useLoveMessageContext = () =>
  useContext(LoveMessageContext);
