import {
  ComponentPropsWithoutRef,
  CSSProperties,
  forwardRef,
} from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import qs from 'qs';

import { isMatch } from 'lib/javascript';

export type LinkExactString = true | false | 'query';
type AnchorProps = ComponentPropsWithoutRef<'a'>;

export type LinkProps = {
  [Property in keyof AnchorProps]?: AnchorProps[Property];
} & {
  to: string;
  children: JSX.Element;
  className?: string;
  activeClassName?: string;
  exact?: LinkExactString;
  style?: CSSProperties;
  activeStyle?: CSSProperties;
  shallow?: boolean;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      to,
      children,
      className,
      activeClassName,
      exact,
      style,
      activeStyle,
      shallow,
      ...props
    },
    ref,
  ) => {
    const router = useRouter();

    if (!router) return children;

    const getIsActive = () => {
      if (exact === 'query') {
        const [toPathname, toQueryStr] = to.split('?');
        const toQuery = qs.parse(toQueryStr);

        const containsToQuery = isMatch(router.query, toQuery);

        return toPathname === router.pathname && containsToQuery;
      }

      if (exact) return router.asPath === to;

      return router.asPath.includes(to);
    };

    const isActive = getIsActive();

    return (
      <NextLink href={to} shallow={shallow}>
        <a
          ref={ref}
          {...props}
          className={
            isActive
              ? `${className || ''} ${activeClassName}`.trim()
              : className
          }
          style={isActive ? { ...style, ...activeStyle } : style}
        >
          {children}
        </a>
      </NextLink>
    );
  },
);

Link.defaultProps = {
  style: {},
  activeClassName: undefined,
  activeStyle: {},
  shallow: false,
};

export default Link;
