import { useEffect } from 'react';
import { useSession } from 'next-auth/client';
import httpClient, {
  setUserToHttpClientConfig,
} from 'lib/httpClient';

const useSetUserToHttpClientLogger = () => {
  const [session] = useSession();

  const user = session?.user;

  useEffect(() => {
    const userInterceptorId = httpClient.interceptors.request.use(
      (config) => {
        setUserToHttpClientConfig(config, user);

        return config;
      },
    );

    return () =>
      httpClient.interceptors.request.eject(userInterceptorId);
  }, [user]);
};

export default useSetUserToHttpClientLogger;
