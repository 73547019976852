import { useCallback } from 'react';
import useSWR from 'swr';
import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';
import { merge } from 'lib/javascript';

import { stripBlankQueryParams, makeDataCacheKey } from './utils';

const defaultFetcher = ({ method, url, data, query }) =>
  httpClient({ method, url, data, params: query })
    .then(getResponseData)
    .catch(getErrorMessage);

const defaultFetchers = {
  create: (url, data) =>
    defaultFetcher({ method: 'post', url, data }),
  read: (url, query) => defaultFetcher({ method: 'get', url, query }),
  update: (url, data) => defaultFetcher({ method: 'put', url, data }),
  delete: (url, data) =>
    defaultFetcher({ method: 'delete', url, data }),
};

const getFetchers = (options) => {
  if (!options?.fetchers) return defaultFetchers;
  return merge({}, defaultFetchers, options.fetchers);
};

/**
 *
 * @param {String | null} url The key to store cache. Usually this is the endpoint URL.
 * @param {String} keyField The field to be used as the key identifier for each list item. Usually this is 'id'.
 * @param {Object} options Additional options. Extending https://swr.vercel.app/docs/options#options
 * @param {Object} options.query Query param to be used during read
 * @param {Object} options.fetchers Custom request for each operation i.e. create, read, update, and delete. (Only create and read is supported now)
 * @param {Function} options.fetchers.create
 * @param {Function} options.fetchers.read
 * @returns {{ data: Array | Object, error: Object, isValidating: Boolean, create: Function, mutate: Function, isLoading: Boolean }}
 */
const useListCRUD = (url, keyField, options) => {
  const fetchers = getFetchers(options);

  const _url = makeDataCacheKey(url, options?.query);

  const { data, error, isValidating, mutate } = useSWR(
    _url,
    (url) =>
      fetchers.read(url, stripBlankQueryParams(options?.query)),
    options,
  );

  const isLoading = !data && !error;

  const create = useCallback(
    async (newObj) => {
      try {
        if (!data || !mutate) return;

        const resp = await fetchers.create(url, newObj);

        return resp;
      } catch (err) {
        console.error(
          `something went wrong when creating data for ${url}`,
          err,
        );

        throw err;
      } finally {
        mutate(); // trigger a revalidation (refetch) to make sure our local data is correct
      }
    },
    [url, data, fetchers, mutate],
  );

  const onDelete = useCallback(
    async (newObj) => {
      try {
        if (!data || !mutate) return;

        const resp = await fetchers.delete(url, newObj);

        return resp;
      } catch (err) {
        console.error(
          `something went wrong when delete data for ${url}`,
          err,
        );

        throw err;
      } finally {
        mutate(); // trigger a revalidation (refetch) to make sure our local data is correct
      }
    },
    [url, data, fetchers, mutate],
  );

  const update = useCallback(
    async (newObj) => {
      try {
        if (!data || !mutate) return;

        const resp = await fetchers.update(url, newObj);

        return resp;
      } catch (err) {
        console.error(
          `something went wrong when updating data for ${url}`,
          err,
        );

        throw err;
      } finally {
        mutate(); // trigger a revalidation (refetch) to make sure our local data is correct
      }
    },
    [url, data, fetchers, mutate],
  );

  return {
    data,
    error,
    isValidating,
    create,
    onDelete,
    update,
    mutate,
    isLoading,
  };
};

export default useListCRUD;
