import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  PaymentMethod,
  PaymentDetails,
  CreditCardDetails,
} from './types';
import { useUserCards } from './card';

interface PaymentContextOptions {
  paymentDetails: PaymentDetails;
  cards: CreditCardDetails[];
  isFetchingCards: boolean;
  onPaymentMethodChange: (method: PaymentMethod) => void;
  onPaymentIdChange: (paymentId: string) => void;
}

const defaultValues: PaymentContextOptions = {
  paymentDetails: {
    method: 'card',
    paymentId: '',
  },
  cards: [],
  isFetchingCards: false,
  onPaymentMethodChange: () => {},
  onPaymentIdChange: () => {},
};

export const PaymentContext = createContext<PaymentContextOptions>(
  defaultValues,
);

export const PaymentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [
    paymentDetails,
    setPaymentDetails,
  ] = useState<PaymentDetails>({
    method: 'card',
    paymentId: '',
  });

  const onPaymentMethodChange = useCallback(
    (method: PaymentMethod) => {
      setPaymentDetails((prevDetails) => ({
        ...prevDetails,
        method,
      }));
    },
    [],
  );

  const onPaymentIdChange = useCallback((paymentId: string) => {
    setPaymentDetails((prevDetails) => ({
      ...prevDetails,
      paymentId,
    }));
  }, []);

  const { cards, isLoading: isCardLoading } = useUserCards();

  const contextValue = useMemo<PaymentContextOptions>(
    () => ({
      paymentDetails,
      cards,
      isFetchingCards: isCardLoading,
      onPaymentMethodChange,
      onPaymentIdChange,
    }),
    [
      paymentDetails,
      cards,
      isCardLoading,
      onPaymentMethodChange,
      onPaymentIdChange,
    ],
  );

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => useContext(PaymentContext);
