import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';

export const getUserById = async (userId) => {
  try {
    const response = await httpClient.get(`/users/${userId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUserById = async (userId, values) => {
  try {
    const response = await httpClient.put(`/users/${userId}`, values);

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const putUserPasswordById = async (userId, values) => {
  try {
    const response = await httpClient.put(
      `/users/${userId}/password`,
      values,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postSetUserPhoneNumber = async (
  userId,
  { phone_number },
) => {
  try {
    const response = await httpClient.put(`/users/${userId}/phone`, {
      phone_number,
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};
