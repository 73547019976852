import { routePaths } from 'appConfig';
import { isBrowser } from 'utils';
import qs from 'qs';

const makePathnameWithUrlParams = (
  path,
  { URLParams, urlParamPattern },
) => {
  if (!URLParams) return path;

  const pathnameWithUrlParams = Object.keys(URLParams).reduce(
    (to, paramName) => {
      return to.replace(
        urlParamPattern(paramName),
        URLParams[paramName],
      );
    },
    path,
  );

  return pathnameWithUrlParams;
};

export const pathToTo = (
  path,
  {
    URLParams,
    queryParams,
    urlParamPattern = (paramName) => `[${paramName}]`,
  } = {},
) => {
  const pathnameWithUrlParams = makePathnameWithUrlParams(path, {
    URLParams,
    urlParamPattern,
  });

  const queryParamString = qs.stringify(queryParams, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  const pathnameWithUrlParamsAndQueryParams = pathnameWithUrlParams.concat(
    queryParamString,
  );

  return pathnameWithUrlParamsAndQueryParams;
};

export const getProductPath = (slugOrProductId) =>
  pathToTo(routePaths.PRODUCT, {
    URLParams: {
      slugOrProductId,
    },
  });

export const makeFullUrlFromPath = (path) => {
  if (!isBrowser()) return null;

  return `${window.location.origin}${path}`;
};
