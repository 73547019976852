import { Router } from 'next/router';
import { useEffect } from 'react';

interface UsePageViewOptions {
  disabled?: boolean;
}

const usePageView = ({ disabled }: UsePageViewOptions): void => {
  useEffect(() => {
    if (disabled) return;

    const handleRouteChange = (): void => {
      window.dataLayer = window.dataLayer || [];

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'page_view',
        });
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router.events, disabled]);
};

export default usePageView;
