import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { Image } from 'components/common';
import { isPaymentMethodCard } from '../utils';

const AcceptedPaymentMethods = ({
  acceptedPaymentMethods,
  className,
  cardOnly,
}) => {
  const showingPaymentMethods = cardOnly
    ? acceptedPaymentMethods.filter(isPaymentMethodCard)
    : acceptedPaymentMethods;

  return (
    <figure className={className}>
      <Row>
        {showingPaymentMethods.map((paymentMethod) => (
          <Col key={paymentMethod} xs="auto">
            <Image
              src={`/assets/images/payments/${paymentMethod}.svg`}
              alt={`Accept ${paymentMethod}`}
              layout="fixed"
              width={30}
              height={20}
            />
          </Col>
        ))}
      </Row>
    </figure>
  );
};

AcceptedPaymentMethods.propTypes = {
  acceptedPaymentMethods: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  className: PropTypes.string,
  /** show payment methods that are card only (do not show if not card)*/
  cardOnly: PropTypes.bool,
};

AcceptedPaymentMethods.defaultProps = {
  cardOnly: false,
};

export default AcceptedPaymentMethods;
