import React from 'react';
import Script from 'next/script';

const OneSignalScript = ({ nonce }) => {
  return (
    <React.Fragment>
      <Script
        src="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
        strategy="lazyOnload"
        nonce={nonce}
      />
      <Script
        id="onesignal-init"
        src="/api/statics/onesignal-init.js"
        strategy="lazyOnload"
        nonce={nonce}
      />
    </React.Fragment>
  );
};

export default OneSignalScript;
