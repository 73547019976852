import * as api from './api';
import { SESSION_CART_KEY } from './constants';

const SessionCart = {
  get cartId() {
    return localStorage.getItem(SESSION_CART_KEY);
  },

  set cartId(newCartId) {
    // use local storage to sync currently active cart ID across browser tabs/windows
    // instead of relying on the cart ID in React state
    // because the React cart state might be outdated and might not be able to update to the latest cart ID before user add to cart
    if (!newCartId) {
      localStorage.removeItem(SESSION_CART_KEY);
      return;
    }

    localStorage.setItem(SESSION_CART_KEY, newCartId);
  },

  use(cart) {
    this.cartId = cart.id;
  },

  forget() {
    this.cartId = null;
  },

  async fetchCart() {
    try {
      const cartId = this.cartId;

      if (!cartId) throw new Error('No session cart ID');

      const cart = await api.getCartById(cartId);

      return cart;
    } catch {
      return null;
    }
  },

  async createNewCart({ isGuest }) {
    const cart = await api.createCart({ isGuest });

    this.use(cart);

    return cart;
  },

  async fetchOrCreateCart({ isGuest }) {
    const cart = await this.fetchCart();

    if (!cart) {
      if (isGuest) return await this.createNewCart({ isGuest });

      const userCart = await api.getUserCart();
      if (userCart) this.use(userCart);
      return userCart;
    }

    return cart;
  },

  async current({ isGuest }) {
    return await this.fetchOrCreateCart({ isGuest });
  },
};

export default SessionCart;
