import { useRouter } from 'next/router';

import notification from 'lib/notification';
import { routePaths } from 'appConfig';
import { validateCartToCheckout } from './utils';

const useCheckoutCart = (cart) => {
  const { push } = useRouter();

  const checkout = () => {
    try {
      validateCartToCheckout(cart);
      push(`${routePaths.CHECKOUT}`);
    } catch (err) {
      notification.error(err.message);
    }
  };

  return {
    checkout,
  };
};

export default useCheckoutCart;
