import QueryString from 'qs';
import { omitBy, isNil } from 'lib/javascript';

export const stripBlankQueryParams = (queryParams) => {
  return omitBy(
    queryParams,
    (queryParam) => isNil(queryParam) || queryParam === '',
  );
};

export const makeDataCacheKey = (key, queryParams) => {
  if (!key) return null;

  if (!queryParams) return key;

  return (
    key +
    QueryString.stringify(stripBlankQueryParams(queryParams), {
      addQueryPrefix: true,
    })
  );
};
