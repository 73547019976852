import packageJson from '../../package.json';
import * as routePaths from './routePaths';
import breadcrumbConfig from './breadcrumbConfig';
import datetime from 'lib/datetime';

const VALENTINE_EVENT_DEFAULT = {
  STARTED_AT: '2022-02-14 00:00:00',
  ENDED_AT: '2022-02-20 23:59:59',
};
const valentineEvent = {
  startedAt:
    process.env.NEXT_PUBLIC_VALENTINE_EVENT_STARTED_AT ??
    VALENTINE_EVENT_DEFAULT.STARTED_AT,
  endedAt:
    process.env.NEXT_PUBLIC_VALENTINE_EVENT_ENDED_AT ??
    VALENTINE_EVENT_DEFAULT.ENDED_AT,
};

const config = {
  appName: 'Yummy Bros',
  appTagline:
    'A Healthy Diet Delivered Straight to You | From $6.50 per meal',
  appDescription:
    'We’ve created healthy renditions of popular Asian cuisines by controlling the ingredients and portions that go into each dish.',
  appVersion: packageJson.version,
  baseURL: process.env.NEXTAUTH_URL,
  appBaseURL: process.env.NEXT_PUBLIC_APP_BASE_URL,
  api: {
    host: process.env.NEXT_PUBLIC_API_HOST,
    version: process.env.NEXT_PUBLIC_API_VERSION,
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  },
  defaultBrand: 'Yummy Bros',
  acceptedPaymentMethods: ['visa', 'mastercard', 'amex'],
  socialMedias: [
    {
      platform: 'Facebook',
      url: 'https://facebook.com/yummybros',
      icon: 'facebook-f',
      iconSet: 'molla',
      className: 'social-facebook',
      label: 'Yummy Bros',
    },
    {
      platform: 'Instagram',
      url: 'https://instagram.com/yummybros',
      icon: 'instagram',
      iconSet: 'molla',
      className: 'social-instagram',
      label: '@YummyBros',
    },
    {
      platform: 'Youtube',
      url: 'https://www.youtube.com/channel/UCApi_DFsoYOdKgiuJIHcVcg',
      icon: 'youtube',
      iconSet: 'molla',
      className: 'social-youtube',
      label: 'Yummy Bros',
    },
    {
      platform: 'Glassdoor',
      url:
        'https://www.glassdoor.sg/Overview/Working-at-Yummy-Bros-EI_IE9305713.11,21.htm',
      icon: 'glassdoor',
      iconSet: 'custom',
      className: 'social-glassdoor',
      label: 'Yummy Bros',
    },
  ],
  timezone: 'Asia/Singapore',
  location: {
    country: 'SG',
  },
  currency: 'SGD',
  locale: 'en-SG',
  cardPaymentGatewayKey:
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  sumoSiteId: process.env.NEXT_PUBLIC_SUMO_SITE_ID,
  welcomeCouponCode: process.env.NEXT_PUBLIC_WELCOME_COUPON_CODE,
  productReviewSaasKey: process.env.NEXT_PUBLIC_YOTPO_API_KEY,
  fbPixelId: process.env.NEXT_PUBLIC_FB_PIXEL_ID,
  gaTrackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  reCAPTCHASiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  oneSignalAppId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
  showDeveloperMenu:
    process.env.NEXT_PUBLIC_SHOW_DEVELOPER_MENU === 'true',
  imgixS3SourceDomain: process.env.NEXT_PUBLIC_IMGIX_S3_SOURCE_DOMAIN,
  imgixWebFolderSourceDomain:
    process.env.NEXT_PUBLIC_IMGIX_WEB_FOLDER_SOURCE_DOMAIN,
  featureToggle: {
    orderInvoice:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_ORDER_INVOICE,
    imageComponent:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_IMAGE_COMPONENT,
    clearAllProductFilters:
      process.env
        .NEXT_PUBLIC_FEATURE_TOGGLE_CLEAR_ALL_PRODUCT_FILTERS,
    newReleasedProductsSection:
      process.env
        .NEXT_PUBLIC_FEATURE_TOGGLE_NEW_RELEASED_PRODUCTS_SECTION,
  },
  fbAppId: process.env.NEXT_PUBLIC_FB_APP_ID,

  // Event
  event: {
    valentine: {
      startAt: datetime.tz(
        valentineEvent.startedAt,
        'Asia/Singapore',
      ),
      endedAt: datetime.tz(valentineEvent.endedAt, 'Asia/Singapore'),
    },
  },
};

export default config;
export { routePaths, breadcrumbConfig };
