import { useEffect } from 'react';
import { useSession } from 'next-auth/client';

import httpClient from 'lib/httpClient';
import { createAuthRefreshInterceptor } from 'lib/http-request-auth-refresh';

import getSession from './getSession';
import { ERROR_CODE } from './constants';

const cache = {
  accessToken: null,
};

const setAccessToken = (accessToken) => {
  cache.accessToken = accessToken;
};

const getAccessToken = () => cache.accessToken;

const useSetAuthTokenToHttpClient = () => {
  const [session] = useSession();

  const accessToken = session?.accessToken;

  useEffect(() => {
    setAccessToken(accessToken);

    const getTokenInterceptor = httpClient.interceptors.request.use(
      async (config) => {
        if (!accessToken) return config;

        config.headers[
          'Authorization'
        ] = `Bearer ${getAccessToken()}`; // must use a function to obtain the fresh accessToken variable value
        // to avoid closure on a stale accessToken variable value

        return config;
      },
    );

    return () => {
      httpClient.interceptors.request.eject(getTokenInterceptor);
    };
  }, [accessToken]);

  useEffect(() => {
    const refreshAuthLogic = async (failedRequest) => {
      try {
        const session = await getSession(); // this will call refresh token API in next-auth jwt callback. See more: pages/api/auth/[...nextauth].js

        if (!session || session.error === ERROR_CODE.REFRESH_TOKEN)
          throw new Error(ERROR_CODE.REFRESH_TOKEN);

        setAccessToken(session.accessToken);
        // update accessToken cache so that the request interceptor can obtain the refreshed access token immediately
        // because session object from useSession hook is not guaranteed to be updated at this point of time
        // on the other hand, based on next-auth documentation, calling getSession() from the client side will update session object across all tabs/windows
        // however, this is not true - the session object is only updated across tabs/windows except the current tab
        // as pointed out here: https://github.com/nextauthjs/next-auth/issues/596#issuecomment-729893390

        failedRequest.response.config.headers[
          'Authorization'
        ] = `Bearer ${session.accessToken}`;

        return;
      } catch (err) {
        throw err;
      }
    };

    const options = {
      statusCodes: [401, 403],
    };

    const authRefreshInterceptor = createAuthRefreshInterceptor(
      httpClient,
      refreshAuthLogic,
      options,
    );

    return () => {
      httpClient.interceptors.response.eject(authRefreshInterceptor);
    };
  }, []);
};

export default useSetAuthTokenToHttpClient;
