import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { merge } from 'lib/javascript';

import defaultOptions from './defaultOptions';

export const configure = (options) => {
  const mergedOptions = merge({}, defaultOptions, options);

  return toast.configure(mergedOptions);
};

// After changing lib from 'toastr' to 'react-toastify',
// 2nd argument title is still being kept around to not break any use sites that pass title argument
export const info = (message, _title, options) =>
  toast.info(message, {
    className: 'tw-bg-info/90 tw-border-2 tw-border-info',
    ...options,
  });

export const warning = (message, _title, options) =>
  toast.warn(message, {
    className: 'tw-bg-warning/90 tw-border-2 tw-border-warning',
    ...options,
  });

export const error = (message, _title, options) =>
  toast.error(message, {
    className: 'tw-bg-error/90 tw-border-2 tw-border-error',
    ...options,
  });

export const success = (message, _title, options) =>
  toast.success(message, {
    className: 'tw-bg-success/90 tw-border-2 tw-border-success',
    ...options,
  });

export const clear = () => toast.dismiss();
