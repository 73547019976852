import { replace, toUpper } from 'lib/javascript';
import {
  COUPON_CODE_QUERY_PARAM,
  COUPON_CODE_FROM_LINK_SESSION_STORAGE_KEY,
} from './constants';

export const formatCouponCode = (rawCouponCode) => {
  const kebabCased = replace(rawCouponCode, ' ', '-');

  const upperCasedAndKebabCased = toUpper(kebabCased);

  return upperCasedAndKebabCased;
};

export const applyCouponCodeOnLink = ({ couponCode, link }) =>
  `${link}?${COUPON_CODE_QUERY_PARAM}=${couponCode}`;

export const setSavedCouponCode = (couponCode) =>
  sessionStorage.setItem(
    COUPON_CODE_FROM_LINK_SESSION_STORAGE_KEY,
    couponCode,
  );

export const getSavedCouponCode = () => {
  if (!window) return;

  return sessionStorage.getItem(
    COUPON_CODE_FROM_LINK_SESSION_STORAGE_KEY,
  );
};

export const removeSavedCouponCode = () => {
  if (!window) return;

  return sessionStorage.removeItem(
    COUPON_CODE_FROM_LINK_SESSION_STORAGE_KEY,
  );
};
