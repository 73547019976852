import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';
import {
  AddressPayload,
  GetUserAddressesQueryParams,
  GetUserAddressesResponse,
  GetUserAddressResponse,
  PostalCodeValidationResponse,
} from './types';
import { createQueryString } from 'lib/utils';

export const getUserAddressesUrl = (
  userId: string,
  params?: GetUserAddressesQueryParams,
) => {
  const formattedQueryString = createQueryString({
    page: params?.page,
    per_page: params?.perPage,
  });

  return `/users/${userId}/addresses?${formattedQueryString}`;
};

export const getUserAddresses = async (
  userId: string,
  params?: GetUserAddressesQueryParams,
) => {
  const response = await httpClient.get<GetUserAddressesResponse>(
    getUserAddressesUrl(userId, params),
  );

  return response.data;
};

export const createNewAddress = async (
  userId: string,
  payload: AddressPayload,
) => {
  const response = await httpClient.post<GetUserAddressResponse>(
    `/users/${userId}/addresses`,
    payload,
  );

  return response.data;
};

export const markAsDefault = async (
  userId: string,
  addressId: string,
) => {
  const response = await httpClient.put<GetUserAddressResponse>(
    `/users/${userId}/addresses/${addressId}/default`,
  );

  return response.data;
};

export const deleteAddress = async (
  userId: string,
  addressId: string,
) => {
  const response = await httpClient.delete(
    `/users/${userId}/addresses/${addressId}`,
  );

  return response.data;
};

export const putUpdateAddressById = async (
  userId,
  addressId,
  address,
) => {
  try {
    const response = await httpClient.put(
      `users/${userId}/addresses/${addressId}`,
      address,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const postValidatePostalCode = async (payload: {
  postal_code: string;
}): Promise<PostalCodeValidationResponse> => {
  try {
    const response = await httpClient.post<PostalCodeValidationResponse>(
      '/validations/postal_codes',
      payload,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
