export const COUNTRIES = {
  SINGAPORE: 'SG',
};

export const LABEL_BY_COUNTRY = {
  [COUNTRIES.SINGAPORE]: 'Singapore',
};

export const countryOptions = Object.values(COUNTRIES).map(
  (countryCode) => ({
    value: countryCode,
    label: LABEL_BY_COUNTRY[countryCode],
  }),
);

export const ADDRESS_TYPE = {
  BILLING: 'billing',
  SHIPPING: 'shipping',
};

export const REQUIRED_ADDRESS_FIELDS = [
  'first_name',
  'last_name',
  'phone_number',
  'address_1',
];

export const POSTAL_CODE_MIN_LENGTH = 6;
export const ORDER_NOTES_TEMPLATE = 'Put inside cooler bag/box. ';
export const DELIVERY_NOTES_MAX_LENGTH = 250;

export const ACTIVE_ADDRESS_SESSION_KEY = 'activeAddress';
