import { useListCRUD } from 'lib/swr-crud';
import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';
import datetime from 'lib/datetime';

const fetchers = {
  read: async (url, query) => {
    try {
      const response = await httpClient({
        method: 'get',
        url,
        query,
      });

      return getResponseData(response).data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
};

const useModifiedDatetimes = ({ startDate, endDate, type }) => {
  const url = '/modified_date';
  const {
    data,
    error,
    mutate,
    create,
    onDelete,
    isValidating,
    isLoading,
  } = useListCRUD(url, 'id', {
    fetchers,
    query: {
      type,
      start_date: datetime(startDate).format('YYYY-MM-DD'),
      end_date: datetime(endDate).format('YYYY-MM-DD'),
      per_page: 50, // this is to prevent some modified dates are left out because the API is paginated
      // the query is usually to fetch modified dates within a month.
      // by design, the total cut-off and delivery datetimes per month is around 35.
      // if this design is changed in the future, the limit might need to be increased to fetch all modified dates
    },
  });

  const modifiedDatetimes = data || [];

  return {
    modifiedDatetimes,
    error,
    mutate,
    createModifiedDatetime: create,
    deleteModifiedDatetime: onDelete,
    isValidating,
    isLoading,
  };
};

export default useModifiedDatetimes;
