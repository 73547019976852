import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { COUPON_CODE_QUERY_PARAM } from './constants';

import { setSavedCouponCode } from './utils';

const useSaveCouponCodeFromLinkForLaterUse = () => {
  const { query } = useRouter();

  const couponCodeFromLink = query[COUPON_CODE_QUERY_PARAM];

  useEffect(() => {
    const saveCouponCodeFromLinkForLaterUse = () => {
      if (!window) return;
      if (!couponCodeFromLink) return;

      setSavedCouponCode(couponCodeFromLink);
    };

    saveCouponCodeFromLinkForLaterUse();
  }, [couponCodeFromLink]);
};

export default useSaveCouponCodeFromLinkForLaterUse;
