import { DISCOUNT_METHOD } from 'lib/discount';

export const mapOrderStatusToOptions = (orderStatus) =>
  orderStatus.map((orderState) => ({
    label: orderState.label,
    value: orderState.name,
  }));

export const isOrderLineUtensil = (orderLine) =>
  orderLine.description === 'utensil';

export const findUtensilOrderLine = (order) => {
  const utensilOrderLine = order.additionals.find(isOrderLineUtensil);

  return utensilOrderLine;
};

export const isUtensilNeeded = (order) => {
  const utensilOrderLine = findUtensilOrderLine(order);

  const needUtensil = Boolean(utensilOrderLine);

  return needUtensil;
};

export const getRedeemedLoyaltyPoints = (order) =>
  order?.discounts?.yb_reps;

export const getEarnedLoyaltyPoints = (order) =>
  order?.rewarded_yb_reps?.given;

export const getEstimatedLoyaltyPointsToBeEarned = (order) =>
  order?.rewarded_yb_reps?.estimated;

export const hasCouponApplied = (order) =>
  order?.discounts?.coupons?.length > 0;

export const getAppliedCoupons = (order) => order?.discounts?.coupons;

export const getAppliedYbReps = (order) => order?.discounts?.yb_reps;

export const hasLoyaltyPointsApplied = (order) =>
  Boolean(getAppliedYbReps(order));

export const getAppliedDiscountMethod = (order) => {
  if (hasCouponApplied(order)) return DISCOUNT_METHOD.COUPON;

  if (hasLoyaltyPointsApplied(order))
    return DISCOUNT_METHOD.LOYALTY_POINTS;

  return DISCOUNT_METHOD.NONE;
};
