/* -------------------------------------------------------------------------- */
/*                               authentication                               */
/* -------------------------------------------------------------------------- */

export const LOGIN = '/auth/login';
export const FORGOT_PASSWORD = '/auth/forgotPassword';
export const RESET_PASSWORD = '/password/confirm';

/* -------------------------------------------------------------------------- */
/*                                 user routes                                */
/* -------------------------------------------------------------------------- */
export const HOME = '/';
export const USER_LOYALTY_POINT = '/account?tab=loyaltyPoints';
export const USER_REFER_A_FRIEND = '/account?tab=referAFriend';

/* -------------------------------- products -------------------------------- */

export const CATEGORIES = '/categories';
export const CATEGORY = '/categories/[categoryId]';
export const PRODUCTS = '/products';
export const PRODUCT = '/product/[slugOrProductId]';
export const CATALOGUES = '/catalogues';

/* ---------------------------------- shop ---------------------------------- */
export const CART = '/cart';
export const CHECKOUT = '/checkout';
export const CHECKOUT_RESULT = '/checkout/result';

/* --------------------------------- account -------------------------------- */
export const ACCOUNT = '/account';
export const ACCOUNT_LOYALTY = '/account?tab=loyaltyPoints';

/* --------------------------------- order -------------------------------- */
export const ORDERS = '/account?tab=orders';
export const ORDER_DETAIL = '/orders/[orderId]';

/* ------------------------------ static pages ------------------------------ */

export const BUFFET = '/pages/buffet';
export const CLICK_AND_COLLECT = '/pages/click-and-collect';
export const CONTACT_US = '/pages/contact';
export const DOORSTEP_DELIVERY = '/pages/doorstep-delivery';
export const GIFT_CARDS = '/giftcards';
export const HOW_MANY_MEALS_TO_ORDER =
  '/pages/how-many-meals-to-order';
export const HOW_TO_ORDER = '/pages/how-to-order';
export const IS_YUMMY_BROS_HALAL = '/pages/is-yummy-bros-halal';
export const MEALCARE = '/pages/mealcare';
export const WEIGHT_LOSS_TIPS = '/pages/weight-loss-tips';
export const NUTRI_PARTY_SETS = '/pages/nutripartysets';
export const OUR_STORY = '/pages/our-story';
export const RECEIVING_YOUR_MEALS = '/pages/receiving-your-meals';
export const RECYCLE = '/pages/recycle';
export const TERMS_OF_USE = '/pages/terms-of-use';
export const WARM_BENTO_CATERING = '/pages/warm-bento-catering';
export const WEEKLY_MEAL_PLANNER = '/pages/weekly-meal-planner';
export const YB_REPS = '/pages/yb-reps';
export const CAUSES_WE_SUPPORTED = '/pages/causes-we-supported';
export const MY_FITNESS_PAL = '/pages/my-fitness-pal';

export const YB_EXPRESS = '/ybexpress';

export const PAYMENT_CALLBACK = '/payment/callback';

/* ------------------------------ socials ------------------------------ */

export const FACEBOOK = 'https://facebook.com/yummybros';
export const INSTAGRAM = 'https://instagram.com/yummybros';
export const YOUTUBE =
  'https://www.youtube.com/channel/UCApi_DFsoYOdKgiuJIHcVcg';
