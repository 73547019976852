import { createContext } from 'react';

const initialValues = {
  confirmationState: null,
  alert: () => {},
  confirm: () => {},
  closeConfirmation: () => {},
  yes: () => {},
  no: () => {},
};

const ConfirmationContext = createContext(initialValues);

export default ConfirmationContext;
