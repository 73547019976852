import { APIListResponse, createQueryString } from 'lib/utils';
import { LoveMessage, LoveMessagesQueryParams } from './types';
import httpClient, { getResponseData } from 'lib/httpClient';

export const getLoveMessagesUrl = (
  userId: string,
  params?: LoveMessagesQueryParams,
) => {
  const formattedQueryString = createQueryString({
    page: params?.page,
    per_page: params?.perPage,
  });

  return `/users/${userId}/love_messages?${formattedQueryString}`;
};

export const getLoveMessages = async (
  userId: string,
  params?: LoveMessagesQueryParams,
) => {
  const response = await httpClient.get<APIListResponse<LoveMessage>>(
    getLoveMessagesUrl(userId, params),
  );

  return response.data;
};

export const dismiss = async (
  userId: string,
  loveMessageId: string,
) => {
  const response = await httpClient.put(
    `/users/${userId}/love_messages/${loveMessageId}/dismiss`,
    { dismiss: true },
  );

  return getResponseData(response).data;
};
