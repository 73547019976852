export const ERROR_CODE = {
  REFRESH_TOKEN: 'ErrorRefreshingToken',
};

export const AUTH_STATUS = {
  LOADING: 'loading',
  SIGNING_IN: 'signing in',
  SIGN_IN_SUCCESS: 'sign in success',
  SIGN_IN_FAILED: 'sign in failed',
  AUTHENTICATED: 'authenticated',
  SIGNING_OUT: 'signing out',
  SIGN_OUT_SUCCESS: 'sign out success',
  SIGN_OUT_FAILED: 'sign out failed',
  UNAUTHENTICATED: 'unauthenticated',
};
