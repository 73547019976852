import { useEffect } from 'react';
import notification from 'lib/notification';
import {
  getSavedCouponCode,
  useApplyCouponCodeFormContext,
  removeSavedCouponCode,
} from 'lib/coupons';
import { useDiscountContext, DISCOUNT_METHOD } from 'lib/discount';

import { hasCouponApplied } from './utils';
import useFetchCart from './CartContext/useFetchCart';

const useMaybeApplySavedCouponCodeToCart = () => {
  const { cart, applyCouponToCart } = useFetchCart();
  const {
    setCouponCode,
    setCouponCodeError,
  } = useApplyCouponCodeFormContext();
  const { setMethod: setDiscountMethod } = useDiscountContext();

  useEffect(() => {
    const maybeApplySavedCouponCodeToCart = async () => {
      try {
        const savedCouponCode = getSavedCouponCode();

        if (savedCouponCode && !hasCouponApplied(cart)) {
          setDiscountMethod(DISCOUNT_METHOD.COUPON);

          setCouponCode(savedCouponCode);

          await applyCouponToCart(savedCouponCode);
          notification.success(
            'Yay! Your coupon code from link is applied successfully! ',
          );

          setCouponCode('');
          setCouponCodeError(null);
          removeSavedCouponCode();
        }
      } catch (err) {
        setCouponCodeError(err.errors.message);
      }
    };

    if (cart) maybeApplySavedCouponCodeToCart();
  }, [
    cart,
    setCouponCode,
    setCouponCodeError,
    setDiscountMethod,
    applyCouponToCart,
  ]);
};

export default useMaybeApplySavedCouponCodeToCart;
