import React from 'react';
import { useRouter } from 'next/router';
import * as analytics from 'lib/analytics';
import {
  signIn as NASignIn,
  signOut as NASignOut,
  useSession,
} from 'next-auth/client';
import Cookies from 'js-cookie';
import { encrypt } from 'lib/encryption';

import {
  postJwtLogout,
  postRegistration,
  postSendResetPasswordEmail,
  postResetPassword,
} from './api';
import { LoggedInTabTracker } from './services';
import { putUserById } from 'lib/account';
import { useAuthStatus } from './AuthStatusContext';
import { AUTH_STATUS } from './constants';

const useAuth = () => {
  const { query, push } = useRouter() ?? {};
  const [session, loading] = useSession();
  const [loggingOut, setLoggingOut] = React.useState(false);
  const { setAuthStatus } = useAuthStatus();

  const register = async ({
    redirect = true,
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    kycData,
    is_subscribe_to_mailchimp,
  }) => {
    const { response, error } = await postRegistration({
      firstName,
      lastName,
      phoneNumber,
      email,
      password: await encrypt(password),
      is_subscribe_to_mailchimp,
    });

    analytics.completeRegistration({ isSuccess: !error });

    if (error) throw error;

    await signIn({
      redirect,
      email,
      password,
      rememberMe: true,
    });

    //if it is redirect, stop following action, to prevent memory leak
    if (redirect) {
      return;
    }

    if (kycData) {
      const _kycData = {
        ...response.data,
        ...kycData,
      };
      await putUserById(response.data.user_id, _kycData);
    }
  };

  const signIn = async ({
    redirect = true,
    email,
    password,
    rememberMe,
  }) => {
    setAuthStatus(AUTH_STATUS.SIGNING_IN);

    const callbackUrl = redirect
      ? query.callbackUrl || '/'
      : undefined;

    if (rememberMe)
      Cookies.set('rememberMe', rememberMe, {
        expires: 14,
      });

    const { error, status, ok, url } = await NASignIn('credentials', {
      redirect: false,
      email,
      password: await encrypt(password),
      callbackUrl,
    });

    setAuthStatus(
      error
        ? AUTH_STATUS.SIGN_IN_FAILED
        : AUTH_STATUS.SIGN_IN_SUCCESS,
    );

    if (ok && url && redirect) {
      push(url);
      LoggedInTabTracker.isLoggedInFromCurrentTab = true;
    }

    return { error, status, ok, url };
  };

  const signOut = async ({ redirect = false } = {}) => {
    try {
      setLoggingOut(true);
      setAuthStatus(AUTH_STATUS.SIGNING_OUT);

      const { error } = await postJwtLogout(session.accessToken);

      const unauthorized = error?.response?.status === 401;

      if (error && !unauthorized) throw error;

      setAuthStatus(AUTH_STATUS.SIGN_OUT_SUCCESS);

      return NASignOut({ redirect });
    } catch (error) {
      setAuthStatus(AUTH_STATUS.SIGN_OUT_FAILED);
      throw error;
    } finally {
      setLoggingOut(false);
    }
  };

  const sendResetPasswordEmail = async ({ email }) => {
    const { response, error } = await postSendResetPasswordEmail({
      email,
    });

    if (error) throw error;

    return response;
  };

  const resetPassword = async ({ email, password, token }) => {
    const { response, error } = await postResetPassword({
      email,
      password: await encrypt(password),
      token,
    });

    if (error) throw error;

    return response;
  };

  return {
    session,
    loading,
    register,
    signIn,
    signOut,
    loggingOut,
    sendResetPasswordEmail,
    resetPassword,
  };
};

export default useAuth;
