import { map } from 'lib/javascript';

export const hasProductVariants = (product) =>
  product?.variants?.length > 1;

export const isOutOfStock = (productVariant) =>
  productVariant?.stock <= 0;

export const makeVariantName = (variant, productOptions) =>
  map(variant.options, (optionValue, optionKey) => {
    const optionLabel = productOptions[optionKey].label;

    return `${optionValue} ${optionLabel}`;
  }).join(', ');
