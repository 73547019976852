import React from 'react';
import PropTypes from 'prop-types';

import config from 'appConfig';

import { makePrice } from '../../utils';

const Price = ({ value, currency, locale }) => {
  return (
    <React.Fragment>
      {makePrice(value, locale, currency)}
    </React.Fragment>
  );
};

Price.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,
  locale: PropTypes.string,
};

Price.defaultProps = {
  value: 0,
  currency: config.currency,
  locale: config.locale,
};

export default Price;
