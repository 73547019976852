import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, CloseButton } from 'react-bootstrap';
import { useCookieConsent } from 'use-cookie-consent';

import { Button } from 'components/common';

import {
  grantConsent as grantFbqConsent,
  revokeConsent as revokeFbqConsent,
} from 'lib/fb-pixel';

const CookieConsent = () => {
  const {
    acceptAllCookies,
    declineAllCookies,
    didAcceptAll,
    didDeclineAll,
  } = useCookieConsent();

  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const handleAcceptCookieConsent = useCallback(() => {
    setShowCookieBanner(false);
    acceptAllCookies();
  }, [acceptAllCookies]);

  const handleDeclineCookieConsent = useCallback(() => {
    setShowCookieBanner(false);
    declineAllCookies();
  }, [declineAllCookies]);

  const grantThirdPartyCookies = useCallback(() => {
    grantFbqConsent();
  }, []);

  const revokeThirdPartyCookies = useCallback(() => {
    revokeFbqConsent();
  }, []);

  useEffect(() => {
    const handleCookieConsent = () => {
      const notAcceptedNorDeclined =
        didAcceptAll() && didDeclineAll(); // if not yet accepted or declined, both of the values will be `true` from 'use-cookie-consent'

      if (notAcceptedNorDeclined) {
        setShowCookieBanner(true);
        return;
      }

      if (didAcceptAll()) {
        grantThirdPartyCookies();
        return;
      }

      if (didDeclineAll()) {
        revokeThirdPartyCookies();
        return;
      }
    };

    handleCookieConsent();
  }, [
    didAcceptAll,
    didDeclineAll,
    grantThirdPartyCookies,
    revokeThirdPartyCookies,
  ]);

  if (!showCookieBanner) return null;

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        zIndex: 99,
        backgroundColor: '#fff',
        boxShadow: '0 -3px 6px rgb(51 51 51 / 5%)',
      }}
    >
      <Container>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1.8rem 0',
          }}
        >
          <Col xs={12} md={8}>
            <h4 style={{ marginBottom: '0.5rem' }}>
              Low-Fat Cookies
            </h4>
            <p
              style={{
                marginBottom: '1rem',
                fontSize: '1.3rem',
                lineHeight: '1.5rem',
              }}
            >
              This website uses cookies to enhance user experience and
              to analyze performance and traffic on our website. We
              also share information about your use of our site with
              our social media, advertising and analytics partners.{' '}
            </p>
          </Col>
          <Col
            xs="auto"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              color="secondary"
              onClick={handleAcceptCookieConsent}
            >
              ACCEPT
            </Button>
            <div style={{ marginLeft: '12px' }}>
              <CloseButton
                onClick={handleDeclineCookieConsent}
                style={{ fontSize: '24px' }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CookieConsent.propTypes = {};

export default CookieConsent;
