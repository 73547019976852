import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getEncryptionPublicKeyBase64 = async () => {
  const NO_PUBLIC_KEY_IN_RESPONSE_ERR_MSG =
    'No public key found in response. ';

  try {
    const res = await httpClient.get('/encryption/sodium_box_seal');

    const data = getResponseData(res);

    const publicKey = data?.data?.keys?.public;

    if (!publicKey)
      throw new Error(NO_PUBLIC_KEY_IN_RESPONSE_ERR_MSG);

    return publicKey;
  } catch (err) {
    if (err.message === NO_PUBLIC_KEY_IN_RESPONSE_ERR_MSG)
      throw err.message;

    throw getErrorMessage(err);
  }
};
