import datetime, { sortDateTimesAsc } from 'lib/datetime';
import React from 'react';
import {
  DELIVERY_SLOT_CONDITION,
  OPERATION_DATETIME_TYPE,
} from './constants';
import useModifiedDatetimes from './useModifiedDatetimes';
import {
  getCutOffDates,
  getCutOffTimesByDate,
  getDeliveryDates,
  getDeliveryTimesByDate,
} from 'lib/deliveryDateTimes/utils';
import { useStoreConfig } from 'lib/store';

const DeliverySlotsContext = React.createContext({
  modifiedDatetimes: [],
  maxRangeDays: 30,
  isLoading: true,
  getAvailableOperationDateTimes: () => {},
  nextCutOffTime: getCutOffDates({
    start: datetime(),
    end: datetime().add(30, 'day'),
  }).map((date) => getCutOffTimesByDate(date))[0],
  refreshCutOffTime: () => {},
  getModifiedDataByTimeSlot: () => {},
  availableDeliverySlots: getDeliveryDates({
    start: datetime(),
    end: datetime().add(30, 'day'),
  })
    .map((date) => getDeliveryTimesByDate(date))
    .flat(),
  nextDeliverySlot: datetime(),
});

export const useDeliverySlotsContext = () => {
  return React.useContext(DeliverySlotsContext);
};

export const DeliverySlotsContextProvider = ({ children }) => {
  const [currentTime, setCurrentTime] = React.useState(datetime());
  const maxRangeDays = 30;
  const { modifiedDatetimes = [], isLoading } = useModifiedDatetimes({
    startDate: currentTime,
    endDate: currentTime.add(maxRangeDays, 'day'),
  });
  const { storeConfig } = useStoreConfig();

  const getModifiedDataByTimeSlot = React.useCallback(
    (timeSlot) => {
      if (!modifiedDatetimes) return null;

      const found = modifiedDatetimes.find((item) => {
        return datetime(item.blocked_datetime).isSame(
          datetime(timeSlot),
        );
      });

      if (!found) return null;

      return found;
    },
    [modifiedDatetimes],
  );

  const applyOperationModificationToDateTimes = React.useCallback(
    (dateTimes) => {
      return dateTimes
        .map((dateTime) => {
          const modifiedData = getModifiedDataByTimeSlot(dateTime);

          if (!modifiedData) return dateTime;

          const { condition, move_to } = modifiedData;

          switch (condition) {
            case DELIVERY_SLOT_CONDITION.BLOCKED:
              return null;

            case DELIVERY_SLOT_CONDITION.MOVED:
              return datetime(move_to);

            default:
              return dateTime;
          }
        })
        .filter(Boolean); // remove blocked deliverySlot
    },
    [getModifiedDataByTimeSlot],
  );

  const getAvailableOperationDateTimes = React.useCallback(
    ({ start, end, operationDateTimeType }) => {
      let getOperationDates = () => {};
      let getOperationDateTimes = () => {};

      switch (operationDateTimeType) {
        case OPERATION_DATETIME_TYPE.CUTOFF:
          getOperationDates = getCutOffDates;
          getOperationDateTimes = getCutOffTimesByDate;
          break;

        case OPERATION_DATETIME_TYPE.DELIVERY:
          getOperationDates = getDeliveryDates;
          getOperationDateTimes = getDeliveryTimesByDate;
          break;

        default:
          throw Error(
            'unrecognized operationDateTimeType passed as argument',
          );
      }

      const operationDates = getOperationDates({
        start,
        end,
      });

      const operationDateTimes = operationDates.flatMap((date) =>
        getOperationDateTimes(date, {
          timezone: storeConfig?.timezone,
        }),
      );

      const operationDateTimesAfterMods = applyOperationModificationToDateTimes(
        operationDateTimes,
      );

      const moddedOperationDateTimesWithinRange = operationDateTimesAfterMods.filter(
        (cutOffDateTime) => cutOffDateTime.isAfter(start),
      );

      return moddedOperationDateTimesWithinRange;
    },
    [applyOperationModificationToDateTimes, storeConfig?.timezone],
  );

  const nextCutOffTime = React.useMemo(() => {
    const availableCutOffDateTimes = getAvailableOperationDateTimes({
      start: currentTime,
      end: currentTime.add(maxRangeDays, 'day'),
      operationDateTimeType: OPERATION_DATETIME_TYPE.CUTOFF,
    });

    const [nextCutOffDateTime] = sortDateTimesAsc(
      availableCutOffDateTimes,
    );

    return nextCutOffDateTime;
  }, [getAvailableOperationDateTimes, currentTime]);

  const refreshCutOffTime = () => {
    setCurrentTime(datetime());
  };

  const availableDeliverySlots = React.useMemo(
    () =>
      getAvailableOperationDateTimes({
        start: datetime(),
        end: datetime().add(maxRangeDays, 'day'),
        operationDateTimeType: OPERATION_DATETIME_TYPE.DELIVERY,
      }),
    [getAvailableOperationDateTimes],
  );

  const nextDeliverySlot = React.useMemo(() => {
    const [nextDeliveryDateTime] = sortDateTimesAsc(
      availableDeliverySlots,
    );

    return nextDeliveryDateTime;
  }, [availableDeliverySlots]);

  return (
    <DeliverySlotsContext.Provider
      value={{
        isLoading,
        modifiedDatetimes,
        getAvailableOperationDateTimes,
        nextCutOffTime,
        getModifiedDataByTimeSlot,
        availableDeliverySlots,
        nextDeliverySlot,
        refreshCutOffTime,
        maxRangeDays,
      }}
    >
      {children}
    </DeliverySlotsContext.Provider>
  );
};
