import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../Image';
import config from 'appConfig';

const VARIANT = {
  LOGO_HORIZONTAL: 'logo-horizontal',
  LOGO_VERTICAL: 'logo-vertical',
  LOGOMARK: 'logomark',
};

const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

const Logo = ({ variant, theme, size, unoptimized }) => {
  const src = getSrc({ variant, theme });
  const { width, height } = getWidthHeight({ variant, size });
  const alt = getAlt({ variant, theme });

  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      unoptimized={unoptimized}
    />
  );
};

const getSrc = ({ variant, theme }) => {
  const base = '/assets/images/';
  let src = base;

  switch (variant) {
    case VARIANT.LOGO_HORIZONTAL:
      src += 'logo-horizontal';
      break;

    case VARIANT.LOGO_VERTICAL:
      src += 'logo-vertical';
      break;

    case VARIANT.LOGOMARK:
      src += 'logomark';
      break;

    default:
  }

  switch (theme) {
    case THEME.DARK:
      src += '-dark';
      break;

    default:
  }

  src += '.svg';

  return src;
};

const getWidthHeight = ({ variant, size }) => {
  let aspectRatio;

  switch (variant) {
    case VARIANT.LOGO_HORIZONTAL:
      aspectRatio = 1259 / 278;
      break;
    case VARIANT.LOGO_VERTICAL:
    case VARIANT.LOGOMARK:
    default:
      aspectRatio = 1; // square
      break;
  }

  const scaledSize = size * 10;
  const width = aspectRatio * scaledSize;
  const height = scaledSize;

  return { width, height };
};

const getAlt = ({ variant, theme }) => {
  const base = config.appName;
  let alt = base;

  switch (variant) {
    case VARIANT.LOGO_VERTICAL:
      alt += ' Logo Vertical';
      break;
    case VARIANT.LOGO_HORIZONTAL:
      alt += ' Logo Horizontal';
      break;
    case VARIANT.LOGOMARK:
      alt += ' Logomark';
      break;
    default:
      alt += ' Logo';
  }

  switch (theme) {
    case THEME.DARK:
      alt += ' Dark';
      break;
    default:
  }

  return alt;
};

Logo.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  theme: PropTypes.oneOf(Object.values(THEME)),
  unoptimized: PropTypes.bool,
  size: PropTypes.number,
};

Logo.defaultProps = {
  variant: VARIANT.LOGO_HORIZONTAL,
  theme: THEME.LIGHT,
  unoptimized: false,
  size: 3.8,
};

export default Logo;
