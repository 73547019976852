import httpClient, {
  getErrorMessage,
  getResponseData,
} from 'lib/httpClient';

export const getUserCart = async () => {
  try {
    const response = await httpClient.get('/carts/user');

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};

export const getCartById = async (cartId) => {
  try {
    const response = await httpClient.get(`/carts/${cartId}`);

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};

export const createCart = async ({ isGuest = false } = {}) => {
  try {
    const response = await httpClient.post('/carts', null, {
      transformRequest: [
        (data, headers) => {
          // including Authorization header would result in creating a user cart
          // to make sure that a guest cart is created, must pass in `isGuest=true`
          if (isGuest) delete headers.common['Authorization'];
          return data;
        },
      ],
    });

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};

export const resolveCart = async ({ guestCartId }) => {
  try {
    const response = await httpClient.put(
      `/carts/${guestCartId}/merge`,
    );

    return getResponseData(response).data;
  } catch (err) {
    return;
  }
};

export const removeCart = async (cartId) => {
  try {
    await httpClient.delete(`/carts/${cartId}`);
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const addToCart = async ({
  cartId,
  productVariantId,
  quantity,
  addonOptionValueIds,
}) => {
  try {
    const response = await httpClient.put(
      `/carts/${cartId}/items/quantity`,
      {
        product_variant_id: productVariantId,
        quantity,
        addon_option_value_ids: addonOptionValueIds,
      },
    );

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const updateCartLineQuantity = async ({
  cartId,
  productVariantId,
  quantity,
  addonOptionValueIds,
}) => {
  try {
    const response = await httpClient.put(`/carts/${cartId}/items`, {
      product_variant_id: productVariantId,
      quantity,
      addon_option_value_ids: addonOptionValueIds,
    });

    return getResponseData(response).data;
  } catch (err) {
    throw getErrorMessage(err);
  }
};

export const removeCartLine = async (cartId, cartLineId) => {
  try {
    const response = await httpClient.delete(
      `/carts/${cartId}/items/${cartLineId}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};

export const applyCouponToCart = async (cartId, couponCode) => {
  try {
    const response = await httpClient.post(
      `/carts/${cartId}/coupons/${couponCode}`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};

export const removeCouponFromCart = async (cartId, couponCode) => {
  try {
    await httpClient.delete(`/carts/${cartId}/coupons/${couponCode}`);
  } catch (err) {
    throw err;
  }
};

export const applyLoyaltyPointsToCart = async (cartId) => {
  try {
    const response = await httpClient.post(
      `/carts/${cartId}/yb_reps`,
    );

    return getResponseData(response).data;
  } catch (err) {
    throw err;
  }
};

export const removeLoyaltyPointsFromCart = async (cartId) => {
  try {
    await httpClient.delete(`/carts/${cartId}/yb_reps`);
  } catch (err) {
    throw err;
  }
};
