import { useAuthEventListener } from 'lib/auth';
import SessionCart from './SessionCart';

const useForgetCartOnLogout = () => {
  useAuthEventListener({
    onSignOutSuccess: () => SessionCart.forget(),
  });
};

export default useForgetCartOnLogout;
