import React from 'react';
import PropTypes from 'prop-types';

import { isCoverImage, Asset } from 'lib/media';
import { isEmpty } from 'lib/javascript';

import ProductImage from '../ProductImage';

const ProductCoverImage = ({ productAssets, alt, size, layout }) => {
  const coverImgs = productAssets.filter(isCoverImage);

  if (isEmpty(coverImgs)) return null;

  const src = coverImgs[0].url;

  return (
    <ProductImage src={src} alt={alt} size={size} layout={layout} />
  );
};

ProductCoverImage.propTypes = {
  productAssets: PropTypes.arrayOf(Asset),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default ProductCoverImage;
