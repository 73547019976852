import { AxiosError } from 'axios';
import { SWRConfiguration } from 'swr';

const downtimeDelay = 30000;

export const SWRDefaultConfig = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // // Only retry up to 10 times.
    if (retryCount >= 10) return;

    if (isAxiosError(error)) {
      if (error.response?.status === 404) {
        return;
      }

      if (error.response?.status === 500) {
        setTimeout(() => revalidate({ retryCount }), downtimeDelay);
        return;
      }
    }

    if (isGenericError(error)) {
      setTimeout(() => revalidate({ retryCount }), downtimeDelay);
      return;
    }

    setTimeout(
      () => revalidate({ retryCount }),
      config.loadingTimeout,
    );
  },
} as SWRConfiguration;

const isAxiosError = (e: any): e is AxiosError =>
  e?.isAxiosError === true;

const isGenericError = (e: any) => {
  if (e?.message === 'Server Error') {
    return true;
  }
  return (
    e?.errors?.message ===
    'There are some error on the server please try again later'
  );
};
