import config from 'appConfig';

interface Item {
  itemId: string;
  itemName: string;
  coupon?: string;
  discount?: number;
  itemCategory?: string;
  itemVariant?: string;
  price?: number;
  quantity?: number;
}

type EcommerceEvent =
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'earn_virtual_currency'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'view_cart'
  | 'view_item'
  | 'view_item_list';

interface SendEcommerceEvent {
  event: EcommerceEvent;
  data?: object;
}

interface EcommerceParams {
  currency?: string;
  value: number;
}

type WithSingleItem = EcommerceParams & {
  item: Item;
};

type WithMultipleItems = EcommerceParams & {
  items: Item[];
};

type PurchasingEcommerceParams = WithMultipleItems & {
  transactionId: string;
  shipping?: number;
  coupon?: string;
  tax?: number;
};

const mapToGTMEcommerceItem = (item: Item): object => {
  const {
    itemId,
    itemName,
    coupon,
    discount,
    itemCategory,
    itemVariant,
    price,
    quantity,
  } = item;

  return {
    item_id: itemId,
    item_name: itemName,
    coupon,
    discount,
    item_category: itemCategory,
    item_variant: itemVariant,
    price,
    quantity,
  };
};

const sendEcommerceEvent = ({
  event,
  data,
}: SendEcommerceEvent): void => {
  window.dataLayer = window.dataLayer || [];

  if (window.dataLayer) {
    /**
     * reference:
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
     *
     * clear the previous ecommerce object
     */
    window.dataLayer.push({ ecommerce: null });

    // push new ecommerce object
    window.dataLayer.push({
      event,
      ecommerce: data,
    });
  }
};

export const addToCart = ({
  currency = config.currency,
  value,
  item,
}: WithSingleItem): void => {
  const data = {
    currency,
    value,
    items: [mapToGTMEcommerceItem(item)],
  };

  sendEcommerceEvent({ event: 'add_to_cart', data });
};

export const removeFromCart = ({
  currency = config.currency,
  value,
  item,
}: WithSingleItem): void => {
  const data = {
    currency,
    value,
    items: [mapToGTMEcommerceItem(item)],
  };

  sendEcommerceEvent({ event: 'remove_from_cart', data });
};

export const viewItem = ({
  currency = config.currency,
  value,
  item,
}: WithSingleItem): void => {
  const data = {
    currency,
    value,
    items: [mapToGTMEcommerceItem(item)],
  };

  sendEcommerceEvent({ event: 'view_item', data });
};

export const viewItems = ({
  itemListId,
  itemListName,
  items,
}: {
  itemListId?: string;
  itemListName?: string;
  items: Item[];
}) => {
  const _items = items.map((item) => mapToGTMEcommerceItem(item));

  const data = {
    item_list_id: itemListId,
    item_list_name: itemListName,
    items: _items,
  };

  sendEcommerceEvent({ event: 'view_item_list', data });
};

export const beginCheckout = ({
  currency = config.currency,
  value,
  items,
}: WithMultipleItems): void => {
  const _items = items.map((item) => mapToGTMEcommerceItem(item));

  const data = {
    currency,
    value,
    items: _items,
  };

  sendEcommerceEvent({ event: 'begin_checkout', data });
};

export const purchase = ({
  transactionId,
  currency = config.currency,
  shipping,
  coupon,
  value,
  tax,
  items,
}: PurchasingEcommerceParams): void => {
  const _items = items.map((item) => mapToGTMEcommerceItem(item));

  const data = {
    transaction_id: transactionId,
    value,
    tax,
    shipping,
    currency,
    coupon,
    items: _items,
  };

  sendEcommerceEvent({ event: 'purchase', data });
};

export const viewCart = ({
  currency = config.currency,
  value,
  items,
}: WithMultipleItems) => {
  const _items = items.map((item) => mapToGTMEcommerceItem(item));

  const data = {
    currency,
    value,
    items: _items,
  };

  sendEcommerceEvent({ event: 'view_cart', data });
};
