import { useCRUD } from 'lib/swr-crud';
import { getStoreConfig } from './api';

const useStoreConfig = () => {
  const { isLoading, data, error } = useCRUD(
    'store-config',
    getStoreConfig,
  );

  return {
    isLoading,
    storeConfig: data,
    error,
  };
};

export default useStoreConfig;
