import * as routePaths from './routePaths';

const breadcrumbConfig = {
  /* ---------------------------------- auth ---------------------------------- */

  login: {
    path: routePaths.LOGIN,
    label: 'Login',
  },
  'forgot-password': {
    path: routePaths.FORGOT_PASSWORD,
    label: 'Forgot Password',
  },
  'reset-password': {
    path: routePaths.RESET_PASSWORD,
    label: 'Reset Password',
  },

  /* ---------------------------------- home ---------------------------------- */

  home: {
    path: routePaths.HOME,
    label: 'Home',
  },

  /* -------------------------------- products -------------------------------- */
  categories: {
    path: routePaths.CATEGORIES,
    label: 'Categories',
  },

  category: {
    path: routePaths.CATEGORY,
    label: 'Category',
  },

  product: {
    path: routePaths.PRODUCT,
    label: 'Product',
  },

  /* ---------------------------------- shop ---------------------------------- */
  cart: {
    path: routePaths.CART,
    label: 'Cart',
  },
  checkout: {
    path: routePaths.CHECKOUT,
    label: 'Checkout',
  },

  /* --------------------------------- account -------------------------------- */
  account: {
    path: routePaths.ACCOUNT,
    label: 'My Account',
  },

  /* --------------------------------- order -------------------------------- */
  orders: {
    path: routePaths.ORDERS,
    label: 'Orders',
  },

  order: {
    path: routePaths.ORDER_DETAIL,
    label: 'Order',
  },

  /* ------------------------------ static pages ------------------------------ */

  'contact-us': {
    path: routePaths.CONTACT_US,
    label: 'Contact Us',
  },
  'doorstep-delivery': {
    path: routePaths.DOORSTEP_DELIVERY,
    label: 'Doorstep Delivery',
  },
  'how-many-meals-to-order': {
    path: routePaths.HOW_MANY_MEALS_TO_ORDER,
    label: 'How Many Meals to Order',
  },
  'how-to-order': {
    path: routePaths.HOW_TO_ORDER,
    label: 'How to Order',
  },
  mealcare: {
    path: routePaths.MEALCARE,
    label: 'Meal Care',
  },
  'our-story': {
    path: routePaths.OUR_STORY,
    label: 'Our Story',
  },
  'receiving-your-meals': {
    path: routePaths.RECEIVING_YOUR_MEALS,
    label: 'Receiving Your Meals',
  },
  recycle: {
    path: routePaths.RECYCLE,
    label: 'Recycle',
  },
  'terms-of-use': {
    path: routePaths.TERMS_OF_USE,
    label: 'Terms of Use',
  },
  'yb-reps': {
    path: routePaths.YB_REPS,
    label: 'YB Reps',
  },
  'causes-we-supported': {
    path: routePaths.CAUSES_WE_SUPPORTED,
    label: 'Causes We’ve Supported',
  },
  'my-fitness-pal': {
    path: routePaths.MY_FITNESS_PAL,
    label: 'My Fitness Pal',
  },
};

export default breadcrumbConfig;
