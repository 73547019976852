import React from 'react';
import { Price } from 'lib/core/components';

import { getCartLineUnitPrice } from '../utils';
import { CartLinePropType } from '../typedef';

const CartLineUnitPrice = ({ cartLine }) => {
  return <Price value={getCartLineUnitPrice(cartLine)} />;
};

CartLineUnitPrice.propTypes = {
  cartLine: CartLinePropType.isRequired,
};

export default CartLineUnitPrice;
