import Cookies from 'js-cookie';

import { MAILCHIMP_CAMPAIGN_ID_COOKIE_NAME } from '../constants';

export const setMailchimpCampaignIdToCookie = (
  mailchimpCampaignId,
) => {
  Cookies.set(
    MAILCHIMP_CAMPAIGN_ID_COOKIE_NAME,
    mailchimpCampaignId,
    {
      expires: 30, //days
    },
  );
};

export const getMailchimpCampaignIdFromCookie = () => {
  return Cookies.get(MAILCHIMP_CAMPAIGN_ID_COOKIE_NAME);
};
