import { Provider as NextAuthProvider } from 'next-auth/client';
import PropTypes from 'prop-types';
import { AuthStatusContextProvider } from './AuthStatusContext';

const Provider = ({ session, options, children }) => {
  return (
    <NextAuthProvider session={session} options={options}>
      <AuthStatusContextProvider>
        {children}
      </AuthStatusContextProvider>
    </NextAuthProvider>
  );
};

Provider.propTypes = {
  session: PropTypes.object,
  options: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Provider;
