import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getCategories = async () => {
  try {
    const response = await httpClient.get('/categories');

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getCategoryById = async (id) => {
  try {
    const { response, error } = await getCategories();
    // temporary, should replace with actual get category by ID endpoint

    if (error) throw error;

    const categories = getResponseData(response);

    const category = categories.find(
      (category) => category.id === id,
    );

    return { response: category };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
