import PropTypes from 'prop-types';
import { OverlayTrigger as RBOverlayTrigger } from 'react-bootstrap';

const OverlayTrigger = ({
  // Own Props
  // Reference: https://react-bootstrap.github.io/components/overlays/#overlay-trigger-props
  children,
  defaultShow,
  delay,
  flip,
  onHide,
  onToggle,
  overlay,
  placement,
  popperConfig,
  show,
  target,
  trigger,
  // Props passthrough to underlying Overlay
  // Reference: https://react-bootstrap.github.io/components/overlays/#overlay-props
  rootClose,
  rootCloseEvent,
}) => {
  return (
    <RBOverlayTrigger
      defaultShow={defaultShow}
      delay={delay}
      flip={flip}
      onHide={onHide}
      onToggle={onToggle}
      overlay={overlay}
      placement={placement}
      popperConfig={popperConfig}
      show={show}
      target={target}
      trigger={trigger}
      rootClose={rootClose}
      rootCloseEvent={rootCloseEvent}
    >
      {children}
    </RBOverlayTrigger>
  );
};

OverlayTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  defaultShow: PropTypes.bool,
  delay: PropTypes.number,
  flip: PropTypes.bool,
  onHide: PropTypes.func,
  onToggle: PropTypes.func,
  overlay: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
    .isRequired,
  placement: PropTypes.oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'buttom-start',
    'left-end',
    'left',
    'left-start',
  ]),
  popperConfig: PropTypes.object,
  show: PropTypes.bool,
  target: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  trigger: PropTypes.oneOfType([
    PropTypes.oneOf(['hover', 'click', 'focus']),
    PropTypes.arrayOf(PropTypes.oneOf(['hover', 'click', 'focus'])),
  ]),
};

export default OverlayTrigger;
