import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'components/common';

const ProductImage = ({ src, alt, size, layout }) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={size}
      height={size}
      layout={layout}
      objectFit="contain"
    />
  );
};

ProductImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.number,
};

ProductImage.defaultProps = {
  layout: 'fixed',
};

export default ProductImage;
