import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/common/link';
import { getProductPath } from 'lib/router';
import { typedef } from 'lib/product';
import * as analytics from 'lib/analytics';

const ProductLink = ({ product, children, ...others }) => {
  return (
    <Link
      {...others}
      to={getProductPath(product.slug || product.id)}
      onClick={() => analytics.clickOnProduct({ product })}
    >
      {children}
    </Link>
  );
};

ProductLink.propTypes = {
  product: typedef.Product,
  children: PropTypes.node,
};

export default ProductLink;
