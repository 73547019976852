import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import NextNprogress from 'nextjs-progressbar';

import config from 'appConfig';
import { Provider as AuthProvider } from 'lib/auth';
import { AuthHooks } from 'lib/auth-hooks';
import { AnalyticsPageView } from 'lib/analytics';
import initMockServer from 'api/initMockServer';
import { CookieConsent } from 'lib/cookie-consent';
import { NotificationProvider } from 'lib/notification';
import { useSaveCouponCodeFromLinkForLaterUse } from 'lib/coupons';

import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
} from 'framer-motion';

// import Utils
import { initFunctions } from '../utils';

// import Custom Components
import Header from '../components/common/header';
import Footer from '../components/common/footer';
import MobileMenu from '../components/common/mobile-menu';
import useFontLoader from 'hooks/useFontLoader';
import {
  CartContextProvider,
  MaybeApplySavedCouponCodeToCart,
} from 'lib/cart';
import {
  CardPaymentGatewayProvider,
  loadCardPaymentGateway,
  PaymentContextProvider,
} from 'lib/payment';
import { ConfirmationContextProvider } from 'lib/confirm';
import { DeliverySlotsContextProvider } from 'lib/deliveryDateTimes';
import { YotpoScripts } from 'lib/react-yotpo';
import { FacebookPixelScript } from 'lib/fb-pixel';
import { OneSignalScript } from 'lib/onesignal';
import { useSaveMailchimpCampaignId } from 'lib/mailchimp';

// import Utils
import {
  mobileMenu,
  preventProductDefault,
  removePreventProductDefault,
  stickyHeaderHandler,
} from '../utils';

import 'bootstrap-icons/font/bootstrap-icons.css';
import '../scripts/wdyr';
import '../styles/globals.css';
import '../styles/style.scss';
import GoogleTagManager from 'lib/gtm/GoogleTagManager';
import App from 'next/app';
import { AddressContextProvider } from 'lib/address';
import { LoveMessageContextProvider } from 'lib/love-message';
import { SWRConfig } from 'swr';
import { SWRDefaultConfig } from 'lib/swr-crud';
import { CheckoutContextProvider } from 'lib/checkout';

const InputPhoneNumberModal = dynamic(() =>
  import('lib/account/InputPhoneNumberModal'),
);

if (process.env.NEXT_PUBLIC_API_MOCK_SERVER === 'true')
  initMockServer();

const cardPaymentGatewayLoader = loadCardPaymentGateway(
  config.cardPaymentGatewayKey,
);

function MyApp({ Component, pageProps, err, nonce, router }) {
  const [loginError, setLoginError] = useState(false);

  // load fonts
  useFontLoader();

  initFunctions();
  // store.dispatch(getAllProducts());

  useSaveCouponCodeFromLinkForLaterUse();

  useSaveMailchimpCampaignId();

  // useLogRouterEvents();

  useEffect(() => {
    mobileMenu();

    // set sticky header
    stickyHeaderHandler();
    window.addEventListener('scroll', stickyHeaderHandler);

    // prevent product thumb icons
    preventProductDefault();

    return () => {
      window.removeEventListener('scroll', stickyHeaderHandler);

      // remove listeners of prevent product
      removePreventProductDefault();
    };
  }, []);

  return (
    <React.Fragment>
      {/* next/script must not be used in _document.js, therefore rendered here */}
      <Script
        nonce={nonce}
        src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
        strategy="beforeInteractive"
      />

      <Script
        nonce={nonce}
        id="mcjs"
        src="/api/statics/mailchimp.js"
        strategy="afterInteractive"
      />

      <Script
        nonce={nonce}
        id="sumo"
        async
        src="/api/statics/sumo.js"
        strategy="lazyOnload"
      />

      <YotpoScripts
        nonce={nonce}
        apiKey={config.productReviewSaasKey}
      />

      <FacebookPixelScript nonce={nonce} pixelId={config.fbPixelId} />

      <OneSignalScript nonce={nonce} />

      <GoogleTagManager trackPageViews withNoScript nonce={nonce} />

      <AnalyticsPageView>
        <SWRConfig value={SWRDefaultConfig}>
          <CardPaymentGatewayProvider
            loader={cardPaymentGatewayLoader}
          >
            <AuthProvider session={pageProps.session}>
              <AuthHooks />
              <CheckoutContextProvider>
                <PaymentContextProvider>
                  <CartContextProvider>
                    <ConfirmationContextProvider>
                      <DeliverySlotsContextProvider>
                        <AddressContextProvider>
                          <LoveMessageContextProvider>
                            <>
                              {/* should be handled in _app.js instead of _document.js */}
                              {/* reason: https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md, https://github.com/vercel/next.js/blob/master/errors/no-document-title.md */}
                              <Head>
                                <title>{config.appTagline}</title>
                                <meta
                                  name="viewport"
                                  content="width=device-width, initial-scale=1.0, minimum-scale=1.0"
                                />
                              </Head>

                              <div className="page-wrapper">
                                <Header />
                                <LazyMotion features={domAnimation}>
                                  <AnimatePresence exitBeforeEnter>
                                    <Component
                                      {...pageProps}
                                      loginError={loginError}
                                      setLoginError={setLoginError}
                                      err={err} // Workaround for https://github.com/vercel/next.js/issues/8592
                                      key={router.route}
                                    />
                                  </AnimatePresence>
                                </LazyMotion>

                                <Footer />

                                <NotificationProvider />

                                <MaybeApplySavedCouponCodeToCart />

                                <NextNprogress
                                  color="#ffc231"
                                  startPosition={0.3}
                                  stopDelayMs={200}
                                  height={5}
                                  showOnShallow={false}
                                />
                              </div>

                              <MobileMenu />

                              <CookieConsent />
                              <InputPhoneNumberModal />
                            </>
                          </LoveMessageContextProvider>
                        </AddressContextProvider>
                      </DeliverySlotsContextProvider>
                    </ConfirmationContextProvider>
                  </CartContextProvider>
                </PaymentContextProvider>
              </CheckoutContextProvider>
            </AuthProvider>
          </CardPaymentGatewayProvider>
        </SWRConfig>
      </AnalyticsPageView>
    </React.Fragment>
  );
}

export default MyApp;

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const appProps = await App.getInitialProps({ Component, ctx });

  let nonce = '';

  try {
    nonce = ctx.res?.getHeader('x-nonce');
  } catch (err) {
    console.warn('Cannot get nonce value', err);
    nonce = '';
  }

  return { ...appProps, nonce };
};
