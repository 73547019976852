import { getAddonCost } from 'lib/productAddon';
import { DISCOUNT_METHOD } from 'lib/discount';

/**
 * get total Price of products in cart.
 * @param {Array} cartlines
 * @return {Float} totalPrice
 */
export const getCartTotal = (cartlines) => {
  if (!cartlines) return 0;

  let total = 0;
  for (let i = 0; i < cartlines.length; i++) {
    total +=
      parseInt(cartlines[i].quantity, 10) *
      (cartlines[i].discount
        ? cartlines[i].salePrice
        : cartlines[i].product_variant.price);
  }
  return total;
};

export const getCartLineUnitPrice = (cartline) => {
  const {
    product_variant_id,
    product,
    addon_option_values,
  } = cartline;

  const productVariant = product.variants.find(
    (variant) => variant.id === product_variant_id,
  );

  return productVariant.price + getAddonCost(addon_option_values);
};

/**
 * get number of products in cart
 * @param {Array} cartlines
 * @return {Integer} numbers of cart items in cartlist
 */
export const getCartCount = (cartlines) => {
  if (!cartlines) return 0;

  let total = 0;

  for (let i = 0; i < cartlines.length; i++) {
    total += parseInt(cartlines[i].quantity, 10);
  }

  return total;
};

export const hasCouponApplied = (cart) =>
  cart?.discounts?.coupons?.length > 0;

export const getAppliedCoupons = (cart) => cart?.discounts?.coupons;

export const hasLoyaltyPointsApplied = (cart) =>
  cart?.discounts?.is_apply_yb_reps;

export const getAppliedDiscountMethod = (cart) => {
  if (hasCouponApplied(cart)) return DISCOUNT_METHOD.COUPON;

  if (hasLoyaltyPointsApplied(cart))
    return DISCOUNT_METHOD.LOYALTY_POINTS;

  return DISCOUNT_METHOD.NONE;
};

export const getIsAppliedCouponValid = (cart) => {
  if (!cart) throw new Error('cart should be passed as argument');

  if (!hasCouponApplied(cart))
    throw new Error('cart has no coupon applied');

  return cart.discounts.is_valid;
};

export const validateCartAppliedCoupon = (cart) => {
  const isAppliedCouponInvalid =
    hasCouponApplied(cart) && !getIsAppliedCouponValid(cart);

  if (isAppliedCouponInvalid)
    throw new Error(
      'Sorry, the coupon you applied is invalid. Please remove it to continue!',
    );

  return true;
};

export const validateCartLines = (cart) => {
  cart.items.forEach((cartLine) => {
    if (Boolean(cartLine.error_message))
      throw new Error(cartLine.error_message);
  });
};

export const validateCartToCheckout = (cart) => {
  try {
    validateCartAppliedCoupon(cart);
    validateCartLines(cart);
    return true;
  } catch (err) {
    throw err;
  }
};
