import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from 'lib/auth';
import { getRouterLogger } from './logger';

const useLogRouterEvents = () => {
  const router = useRouter();
  const { session } = useAuth();

  const user = session?.user;

  useEffect(() => {
    const logRouteChangeStart = async (url) => {
      const logger = await getRouterLogger();
      logger.info({ user }, `START route change to ${url}`);
    };

    const logRouteChangeComplete = async (url) => {
      const logger = await getRouterLogger();
      logger.info({ user }, `SUCCESS route change to ${url}`);
    };

    const logRouteChangeError = async (error, url) => {
      const logger = await getRouterLogger();
      logger.error(
        { user, error: JSON.stringify(error) },
        `FAILED route change to ${url}`,
      );
    };

    router.events.on('routeChangeStart', logRouteChangeStart);
    router.events.on('routeChangeComplete', logRouteChangeComplete);
    router.events.on('routeChangeError', logRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', logRouteChangeStart);
      router.events.off(
        'routeChangeComplete',
        logRouteChangeComplete,
      );
      router.events.off('routeChangeError', logRouteChangeError);
    };
  }, [router.events, user]);
};

export default useLogRouterEvents;
