import React from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';

const FacebookPixelScript = ({ pixelId, nonce }) => {
  return (
    <React.Fragment>
      <Script
        nonce={nonce}
        id="facebook-pixel"
        src="/api/statics/facebook-pixel.js"
        strategy="beforeInteractive"
      />
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </React.Fragment>
  );
};

FacebookPixelScript.propTypes = {
  pixelId: PropTypes.string.isRequired,
};

export default FacebookPixelScript;
